// import React, { useEffect, useState } from 'react';
// import { getTrendingCoins } from '../api';
// import { Link } from 'react-router-dom';
// import './TrendingCoin.css'; // Ensure you have this CSS file for styles

// function TrendingCoin() {
//     const [trendingCoins, setTrendingCoins] = useState([]);

//     useEffect(() => {
//         const fetchTrendingCoins = async () => {
//             const response = await getTrendingCoins();
//             if (response && Array.isArray(response.coins)) {
//                 setTrendingCoins(response.coins);
//             } else {
//                 setTrendingCoins([]);
//             }
//         };
//         fetchTrendingCoins();
//     }, []);

//     return (
//         <div className="trending-coin-section">
//             <div className="container">
//                 <div className="row">
//                     <div className="col-md-12 col-sm-12 col-xs-12">
//                         <div className="section-headline text-center">
//                             <h3>Trending Coins</h3>
//                             <p>Latest trending cryptocurrency coins.</p>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="trending-coin-wrapper">
//                     {trendingCoins.map((coin) => (
//                         <div className="trending-coin-card" key={coin.item.id}>
//                             <div className="coin-icon">
//                                 <img src={coin.item.large} alt={coin.item.name} />
//                             </div>
//                             <div className="coin-info">
//                                 <h4>{coin.item.name}</h4>
//                                 <p>{coin.item.symbol.toUpperCase()}</p>
//                                 <p>Price: ${coin.item.data.price.toFixed(4)}</p>
//                                 <p>Market Cap: {coin.item.data.market_cap}</p>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default TrendingCoin;



import React, { useEffect, useState } from 'react';
import { getTrendingCoins } from '../api';
import { Link } from 'react-router-dom';
import './TrendingCoin.css';

function TrendingCoin() {
    const [trendingCoins, setTrendingCoins] = useState([]);

  const data ={
    "coins": [
      {
        "item": {
          "id": "neiro-3",
          "coin_id": 39488,
          "name": "Neiro",
          "symbol": "NEIRO",
          "market_cap_rank": 682,
          "thumb": "https://coin-images.coingecko.com/coins/images/39488/standard/logo.png?1723247657",
          "small": "https://coin-images.coingecko.com/coins/images/39488/small/logo.png?1723247657",
          "large": "https://coin-images.coingecko.com/coins/images/39488/large/logo.png?1723247657",
          "slug": "neiro-3",
          "price_btc": 1.39017034111047e-9,
          "score": 0,
          "data": {
            "price": 0.000082301509518278,
            "price_btc": "0.00000000139017034111047",
            "price_change_percentage_24h": {
              "aed": 121.908703404055,
              "ars": 121.898773893598,
              "aud": 120.498647572306,
              "bch": 120.903610524113,
              "bdt": 120.385386188788,
              "bhd": 121.759363215734,
              "bmd": 121.905984675045,
              "bnb": 121.850359427859,
              "brl": 121.351876957913,
              "btc": 119.038450155945,
              "cad": 121.326698681127,
              "chf": 121.157024366357,
              "clp": 123.638026669722,
              "cny": 121.80382799223,
              "czk": 120.598303367759,
              "dkk": 121.098792382882,
              "dot": 124.785737825333,
              "eos": 118.506597673061,
              "eth": 123.429971595107,
              "eur": 121.035006848952,
              "gbp": 120.928292999697,
              "gel": 121.493520019515,
              "hkd": 121.901201677299,
              "huf": 120.194788491178,
              "idr": 121.747085951488,
              "ils": 121.301877408997,
              "inr": 121.640398298114,
              "jpy": 120.160219728392,
              "krw": 120.867183633462,
              "kwd": 121.575386597386,
              "lkr": 121.071960556894,
              "ltc": 118.028213076195,
              "mmk": 121.905984675045,
              "mxn": 121.730142981576,
              "myr": 122.068672347095,
              "ngn": 120.723794149013,
              "nok": 121.020536257004,
              "nzd": 120.480828854412,
              "php": 121.29162098786,
              "pkr": 120.290465229916,
              "pln": 120.51955483828,
              "rub": 123.607136426178,
              "sar": 121.900248816842,
              "sek": 120.453412333338,
              "sgd": 121.655403073695,
              "thb": 119.235706542184,
              "try": 121.644280859207,
              "twd": 121.374415132919,
              "uah": 120.903589695281,
              "usd": 121.905984675045,
              "vef": 121.905984675045,
              "vnd": 121.908110370558,
              "xag": 116.152894186125,
              "xau": 117.706922143608,
              "xdr": 120.391694125111,
              "xlm": 123.678505132121,
              "xrp": 124.081815745238,
              "yfi": 124.534321425265,
              "zar": 122.435399977289,
              "bits": 119.038450155945,
              "link": 127.180252135615,
              "sats": 119.038450155945
            },
            "market_cap": "$34,668,695",
            "market_cap_btc": "585.604656757609",
            "total_volume": "$30,638,202",
            "total_volume_btc": "517.515659048406",
            "sparkline": "https://www.coingecko.com/coins/39488/sparkline.svg",
            "content": null
          }
        }
      },
      {
        "item": {
          "id": "superrare",
          "coin_id": 17753,
          "name": "SuperRare",
          "symbol": "RARE",
          "market_cap_rank": 313,
          "thumb": "https://coin-images.coingecko.com/coins/images/17753/standard/RARE.png?1709598473",
          "small": "https://coin-images.coingecko.com/coins/images/17753/small/RARE.png?1709598473",
          "large": "https://coin-images.coingecko.com/coins/images/17753/large/RARE.png?1709598473",
          "slug": "superrare",
          "price_btc": 0.000003004825406671,
          "score": 1,
          "data": {
            "price": 0.177893067845483,
            "price_btc": "0.00000300482540667155",
            "price_change_percentage_24h": {
              "aed": 62.0763552430826,
              "ars": 62.069102986458,
              "aud": 61.0464870748067,
              "bch": 61.3422615001925,
              "bdt": 60.9637639010475,
              "bhd": 61.9672810470591,
              "bmd": 62.0743695540038,
              "bnb": 62.0337423177342,
              "brl": 61.669663664477,
              "btc": 59.979996795026,
              "cad": 61.651274104851,
              "chf": 61.5273483908322,
              "clp": 63.3394080555085,
              "cny": 61.9997569653108,
              "czk": 61.1192730803044,
              "dkk": 61.4848172620603,
              "dot": 64.1776664839518,
              "eos": 59.5915455507508,
              "eth": 63.1874500310321,
              "eur": 61.4382299642297,
              "gbp": 61.3602889394932,
              "gel": 61.7731160789958,
              "hkd": 62.0708761766377,
              "huf": 60.8245562914656,
              "idr": 61.9583140520256,
              "ils": 61.6331452921564,
              "inr": 61.8803921600801,
              "jpy": 60.7993081646831,
              "krw": 61.3156562450624,
              "kwd": 61.8329093018578,
              "lkr": 61.4652199930299,
              "ltc": 59.2421458621622,
              "mmk": 62.0743695540038,
              "mxn": 61.9459393467152,
              "myr": 62.1931924055831,
              "ngn": 61.2109282886412,
              "nok": 61.4276610195912,
              "nzd": 61.033472745909,
              "php": 61.6256542684595,
              "pkr": 60.8944360972516,
              "pln": 61.0617571990299,
              "rub": 63.3168466236706,
              "sar": 62.0701802321003,
              "sek": 61.0134484307702,
              "sgd": 61.891351258575,
              "thb": 60.1240677378945,
              "try": 61.8832278818945,
              "twd": 61.6861249623116,
              "uah": 61.3422462873541,
              "usd": 62.0743695540038,
              "vef": 62.0743695540039,
              "vnd": 62.0759221068139,
              "xag": 57.872461636359,
              "xau": 59.0074833071223,
              "xdr": 60.968371053956,
              "xlm": 63.3689724734463,
              "xrp": 63.663540073555,
              "yfi": 63.9940384732142,
              "zar": 62.4610407448194,
              "bits": 59.979996795026,
              "link": 65.9265575640873,
              "sats": 59.979996795026
            },
            "market_cap": "$123,322,457",
            "market_cap_btc": "2083.18973403992",
            "total_volume": "$352,616,763",
            "total_volume_btc": "5956.11634213814",
            "sparkline": "https://www.coingecko.com/coins/17753/sparkline.svg",
            "content": {
              "title": "About SuperRare",
              "description": "Founded in 2018, SuperRare is the leading curated marketplace for onchain art with more than US$300M in total sales and US$180M earned by artists. As one of the earliest champions of perpetual artist royalties, SuperRare is working to improve discovery while decentralizing the art market. Core to SuperRare is $RARE, a token offering utility as a currency on the marketplace, a staking based reputation system, and DAO governance rights."
            }
          }
        }
      },
      {
        "item": {
          "id": "ait-protocol",
          "coin_id": 33962,
          "name": "AIT Protocol",
          "symbol": "AIT",
          "market_cap_rank": 901,
          "thumb": "https://coin-images.coingecko.com/coins/images/33962/standard/ait-logo-200x200.png?1703567800",
          "small": "https://coin-images.coingecko.com/coins/images/33962/small/ait-logo-200x200.png?1703567800",
          "large": "https://coin-images.coingecko.com/coins/images/33962/large/ait-logo-200x200.png?1703567800",
          "slug": "ait-protocol",
          "price_btc": 0.000001615444242353,
          "score": 2,
          "data": {
            "price": 0.0956340712199738,
            "price_btc": "0.00000161544424235347",
            "price_change_percentage_24h": {
              "aed": 5.81548079900773,
              "ars": 5.81459269823004,
              "aud": 5.16499222837934,
              "bch": 5.34053992197213,
              "bdt": 5.08909854779226,
              "bhd": 5.74426906378817,
              "bmd": 5.81418439371624,
              "bnb": 5.73669861189699,
              "brl": 5.54996233480893,
              "btc": 4.44494990830785,
              "cad": 5.54679968185919,
              "chf": 5.48945977525399,
              "clp": 6.6400954716473,
              "cny": 5.76252091525089,
              "czk": 5.20024151009861,
              "dkk": 5.43098881388085,
              "dot": 7.15708480753882,
              "eos": 4.2248981888867,
              "eth": 6.53650306969656,
              "eur": 5.40396130865036,
              "gbp": 5.35137355658153,
              "gel": 5.61750375357957,
              "hkd": 5.81316619577737,
              "huf": 4.97115824847841,
              "idr": 5.74159752957773,
              "ils": 5.52789662053817,
              "inr": 5.68526711062482,
              "jpy": 5.02634251719866,
              "krw": 5.32386352876603,
              "kwd": 5.65654120982069,
              "lkr": 5.41648632371542,
              "ltc": 3.98753461872265,
              "mmk": 5.81418439371676,
              "mxn": 5.75524269875511,
              "myr": 5.92760363683247,
              "ngn": 5.25046581490804,
              "nok": 5.38712802413159,
              "nzd": 5.15276258608365,
              "php": 5.52675951949006,
              "pkr": 5.04383620906088,
              "pln": 5.15702116286741,
              "rub": 6.62447853460998,
              "sar": 5.8114492932703,
              "sek": 5.06865952435469,
              "sgd": 5.69830120309529,
              "thb": 4.58791316674698,
              "try": 5.68752701584226,
              "twd": 5.55465794661627,
              "uah": 5.33619995639021,
              "usd": 5.81418439371624,
              "vef": 5.81418439371618,
              "vnd": 5.81519801548568,
              "xag": 3.07814753099807,
              "xau": 3.87579484358758,
              "xdr": 5.09210643934679,
              "xlm": 6.68767471763622,
              "xrp": 6.86464452248054,
              "yfi": 7.07661985252693,
              "zar": 6.09692397954679,
              "bits": 4.44494990830786,
              "link": 8.33432857665636,
              "sats": 4.44494990830785
            },
            "market_cap": "$20,810,870",
            "market_cap_btc": "351.542792060709",
            "total_volume": "$1,663,898",
            "total_volume_btc": "28.1064531325854",
            "sparkline": "https://www.coingecko.com/coins/33962/sparkline.svg",
            "content": null
          }
        }
      },
      {
        "item": {
          "id": "sui",
          "coin_id": 26375,
          "name": "Sui",
          "symbol": "SUI",
          "market_cap_rank": 40,
          "thumb": "https://coin-images.coingecko.com/coins/images/26375/standard/sui_asset.jpeg?1696525453",
          "small": "https://coin-images.coingecko.com/coins/images/26375/small/sui_asset.jpeg?1696525453",
          "large": "https://coin-images.coingecko.com/coins/images/26375/large/sui_asset.jpeg?1696525453",
          "slug": "sui",
          "price_btc": 0.000013713042826457,
          "score": 3,
          "data": {
            "price": 0.811845923719454,
            "price_btc": "0.0000137130428264571",
            "price_change_percentage_24h": {
              "aed": -6.6235928738669,
              "ars": -6.62777108768561,
              "aud": -7.21692673322564,
              "bch": -7.0465233877105,
              "bdt": -7.26458570692429,
              "bhd": -6.68643335741287,
              "bmd": -6.62473688103294,
              "bnb": -6.64814328938063,
              "brl": -6.85789847854127,
              "btc": -7.83135954430104,
              "cad": -6.86849318252463,
              "chf": -6.93989001217487,
              "clp": -5.8959152711839,
              "cny": -6.6677231355553,
              "czk": -7.17499282082831,
              "dkk": -6.96439330255167,
              "dot": -5.41297277057152,
              "eos": -8.05515641756914,
              "eth": -5.9834622445737,
              "eur": -6.99123345750588,
              "gbp": -7.0361373106725,
              "gel": -6.79829684965591,
              "hkd": -6.62674950666668,
              "huf": -7.34478683439833,
              "idr": -6.69159947666384,
              "ils": -6.87893766341358,
              "inr": -6.73649230693155,
              "jpy": -7.35933293745001,
              "krw": -7.06185136772951,
              "kwd": -6.76384841753898,
              "lkr": -6.97568379935976,
              "ltc": -8.25645467308871,
              "mmk": -6.62473688103294,
              "mxn": -6.69872886650851,
              "myr": -6.5562799432624,
              "ngn": -7.12218786950739,
              "nok": -6.99732250162324,
              "nzd": -7.22442462686845,
              "php": -6.88325343715775,
              "pkr": -7.30452725930368,
              "pln": -7.20812921717712,
              "rub": -5.90891349934295,
              "sar": -6.62715045843019,
              "sek": -7.23596115605569,
              "sgd": -6.73017848502178,
              "thb": -7.74835652392737,
              "try": -6.73485857378008,
              "twd": -6.84841469636882,
              "uah": -7.04653215222266,
              "usd": -6.62473688103294,
              "vef": -6.6247368810329,
              "vnd": -6.62384241494588,
              "xag": -9.04556540864921,
              "xau": -8.39164987934689,
              "xdr": -7.26193140620888,
              "xlm": -5.87888244044389,
              "xrp": -5.7091743783847,
              "yfi": -5.51876564742071,
              "zar": -6.40196554289794,
              "bits": -7.83135954430104,
              "link": -4.4053910954217,
              "sats": -7.83135954430104
            },
            "market_cap": "$2,104,086,332",
            "market_cap_btc": "35542.6834083339",
            "total_volume": "$257,195,396",
            "total_volume_btc": "4344.33600315999",
            "sparkline": "https://www.coingecko.com/coins/26375/sparkline.svg",
            "content": null
          }
        }
      },
      {
        "item": {
          "id": "the-open-network",
          "coin_id": 17980,
          "name": "Toncoin",
          "symbol": "TON",
          "market_cap_rank": 9,
          "thumb": "https://coin-images.coingecko.com/coins/images/17980/standard/ton_symbol.png?1696517498",
          "small": "https://coin-images.coingecko.com/coins/images/17980/small/ton_symbol.png?1696517498",
          "large": "https://coin-images.coingecko.com/coins/images/17980/large/ton_symbol.png?1696517498",
          "slug": "toncoin",
          "price_btc": 0.000110306058508414,
          "score": 4,
          "data": {
            "price": 6.53010310032114,
            "price_btc": "0.000110306058508415",
            "price_change_percentage_24h": {
              "aed": -1.58551730851352,
              "ars": -1.58634329331204,
              "aud": -2.19050908941085,
              "bch": -2.02906168945769,
              "bdt": -2.26109457707424,
              "bhd": -1.6517483177218,
              "bmd": -1.5867230399919,
              "bnb": -1.65585754993312,
              "brl": -1.83246475043611,
              "btc": -2.85729394981297,
              "cad": -1.8354061995667,
              "chf": -1.88873561038189,
              "clp": -0.818578238588691,
              "cny": -1.63477304618012,
              "czk": -2.15772523019251,
              "dkk": -1.94311696717918,
              "dot": -0.334777317694878,
              "eos": -3.06196080555213,
              "eth": -0.911352809890103,
              "eur": -1.96825410142098,
              "gbp": -2.0171637352162,
              "gel": -1.76964734660884,
              "hkd": -1.58767002263245,
              "huf": -2.37078583829401,
              "idr": -1.65423299817999,
              "ils": -1.85298713372828,
              "inr": -1.70662352742203,
              "jpy": -2.31946129468486,
              "krw": -2.04274964321242,
              "kwd": -1.73334026722338,
              "lkr": -1.95660511708545,
              "ltc": -3.28272915810256,
              "mmk": -1.58672303999189,
              "mxn": -1.64154220609826,
              "myr": -1.48123662104585,
              "ngn": -2.11101373827235,
              "nok": -1.98391002412151,
              "nzd": -2.2018833600842,
              "php": -1.85404470309879,
              "pkr": -2.30319115517448,
              "pln": -2.19792263876046,
              "rub": -0.833102888177983,
              "sar": -1.58926684070483,
              "sek": -2.28010404433863,
              "sgd": -1.69450107182129,
              "thb": -2.7272258050916,
              "try": -1.70452168560041,
              "twd": -1.82809756140631,
              "uah": -2.03127605604229,
              "usd": -1.5867230399919,
              "vef": -1.58672303999196,
              "vnd": -1.5857803134519,
              "xag": -4.13139467438819,
              "xau": -3.38953680022865,
              "xdr": -2.25829706494177,
              "xlm": -0.783795002075917,
              "xrp": -0.595603443174109,
              "yfi": -0.40961658449451,
              "zar": -1.32375896454859,
              "bits": -2.85729394981298,
              "link": 0.760159733002126,
              "sats": -2.85729394981297
            },
            "market_cap": "$16,449,554,122",
            "market_cap_btc": "277859.645775113",
            "total_volume": "$435,758,615",
            "total_volume_btc": "7360.80496558228",
            "sparkline": "https://www.coingecko.com/coins/17980/sparkline.svg",
            "content": {
              "title": "What is TON?",
              "description": "TON (The Open Network) is a Layer 1 smart contract network specializing in financial applications. It was first developed by Telegram’s co-founder Nikolai Durov known as “Telegram Open Network” but has been relaunched as “The Open Network” under the TON Foundation."
            }
          }
        }
      },
      {
        "item": {
          "id": "solana",
          "coin_id": 4128,
          "name": "Solana",
          "symbol": "SOL",
          "market_cap_rank": 5,
          "thumb": "https://coin-images.coingecko.com/coins/images/4128/standard/solana.png?1718769756",
          "small": "https://coin-images.coingecko.com/coins/images/4128/small/solana.png?1718769756",
          "large": "https://coin-images.coingecko.com/coins/images/4128/large/solana.png?1718769756",
          "slug": "solana",
          "price_btc": 0.00235111159580674,
          "score": 5,
          "data": {
            "price": 139.185474747131,
            "price_btc": "0.00235111159580674",
            "price_change_percentage_24h": {
              "aed": -3.19783780066371,
              "ars": -3.20216930306588,
              "aud": -3.81293964769027,
              "bch": -3.65357113930489,
              "bdt": -3.86234711413963,
              "bhd": -3.26298374992529,
              "bmd": -3.19902377869216,
              "bnb": -3.25229206798208,
              "brl": -3.44073951262682,
              "btc": -4.44570989396273,
              "cad": -3.45172291073563,
              "chf": -3.52573911779521,
              "clp": -2.44346346252282,
              "cny": -3.24358709517326,
              "czk": -3.76946728122773,
              "dkk": -3.55114137477296,
              "dot": -1.94985766877219,
              "eos": -4.67772754386355,
              "eth": -2.52894061152304,
              "eur": -3.57896623025068,
              "gbp": -3.62551749744345,
              "gel": -3.37895124378764,
              "hkd": -3.20111024270424,
              "huf": -3.94549062746622,
              "idr": -3.26833940162674,
              "ils": -3.46255057442757,
              "inr": -3.31487924151694,
              "jpy": -3.96057039194138,
              "krw": -3.65217494120932,
              "kwd": -3.34323898181646,
              "lkr": -3.5628460926669,
              "ltc": -4.88642012965686,
              "mmk": -3.19902377869213,
              "mxn": -3.27573035158213,
              "myr": -3.12805532105189,
              "ngn": -3.71472504365392,
              "nok": -3.58527866670483,
              "nzd": -3.82071262095946,
              "php": -3.46702468349661,
              "pkr": -3.90375402570239,
              "pln": -3.80381937200532,
              "rub": -2.45693856431011,
              "sar": -3.20152590442077,
              "sek": -3.83267239756664,
              "sgd": -3.30833378071552,
              "thb": -4.36386631514663,
              "try": -3.31318557063781,
              "twd": -3.43090779336896,
              "uah": -3.63629370064865,
              "usd": -3.19902377869216,
              "vef": -3.19902377869214,
              "vnd": -3.19809649680281,
              "xag": -5.70866666386495,
              "xau": -5.03076055159954,
              "xdr": -3.85959543353544,
              "xlm": -2.40155290403002,
              "xrp": -2.2455700633208,
              "yfi": -2.04816700923105,
              "zar": -2.96807949767752,
              "bits": -4.44570989396273,
              "link": -0.89233322104615,
              "sats": -4.44570989396273
            },
            "market_cap": "$64,892,391,042",
            "market_cap_btc": "1096049.97813344",
            "total_volume": "$3,058,367,079",
            "total_volume_btc": "51661.7291850161",
            "sparkline": "https://www.coingecko.com/coins/4128/sparkline.svg",
            "content": {
              "title": "About Solana (SOL)",
              "description": "Solana is a Layer 1 blockchain that offers users fast speeds and affordable costs. It supports smart contracts and facilitates the creation of decentralized applications (dApps). Projects built on Solana include a variety of DeFi platforms as well as NFT marketplaces, where users can buy Solana-based NFT projects. Its high performance means Solana doesn’t require a traditional scaling Layer 2 solution; instead, Layer 2s on Solana focus on interoperability and connecting Solana to other chains. "
            }
          }
        }
      },
      {
        "item": {
          "id": "notcoin",
          "coin_id": 33453,
          "name": "Notcoin",
          "symbol": "NOT",
          "market_cap_rank": 67,
          "thumb": "https://coin-images.coingecko.com/coins/images/33453/standard/rFmThDiD_400x400.jpg?1701876350",
          "small": "https://coin-images.coingecko.com/coins/images/33453/small/rFmThDiD_400x400.jpg?1701876350",
          "large": "https://coin-images.coingecko.com/coins/images/33453/large/rFmThDiD_400x400.jpg?1701876350",
          "slug": "notcoin",
          "price_btc": 1.82196714090556e-7,
          "score": 6,
          "data": {
            "price": 0.0107860197675389,
            "price_btc": "0.000000182196714090556",
            "price_change_percentage_24h": {
              "aed": -0.980142346982586,
              "ars": -0.980973412644054,
              "aud": -1.58885560124505,
              "bch": -1.41893315179099,
              "bdt": -1.65987527995125,
              "bhd": -1.04678076162089,
              "bmd": -0.98135549525184,
              "bnb": -1.04819510238561,
              "brl": -1.22860883157109,
              "btc": -2.2570551043747,
              "cad": -1.23156837437643,
              "chf": -1.28522582928778,
              "clp": -0.208485620699938,
              "cny": -1.02970107044426,
              "czk": -1.55587007935524,
              "dkk": -1.33994170099447,
              "dot": 0.29596419223362,
              "eos": -2.46298658411068,
              "eth": -0.302198837196692,
              "eur": -1.36523346076781,
              "gbp": -1.41444395137663,
              "gel": -1.16540502035341,
              "hkd": -0.982308303047102,
              "huf": -1.77024128274629,
              "idr": -1.04928072604229,
              "ils": -1.2492574537728,
              "inr": -1.10199352405763,
              "jpy": -1.71860102753871,
              "krw": -1.44018724543682,
              "kwd": -1.12887460598558,
              "lkr": -1.35351282027661,
              "ltc": -2.70785973318401,
              "mmk": -0.98135549525151,
              "mxn": -1.03651186935556,
              "myr": -0.875220199889576,
              "ngn": -1.50887125200568,
              "nok": -1.38098568742025,
              "nzd": -1.60029983823304,
              "php": -1.25032152854764,
              "pkr": -1.70223080585716,
              "pln": -1.59631475340648,
              "rub": -0.223099615464502,
              "sar": -0.983914943593039,
              "sek": -1.67900167975654,
              "sgd": -1.08979649984793,
              "thb": -2.12887381130202,
              "try": -1.09987875322027,
              "twd": -1.22421477874188,
              "uah": -1.42864308102816,
              "usd": -0.98135549525184,
              "vef": -0.981355495251755,
              "vnd": -0.980406969737272,
              "xag": -3.5416801153742,
              "xau": -2.79525886629477,
              "xdr": -1.6570605595406,
              "xlm": -0.158241062655316,
              "xrp": 0.00737297515383927,
              "yfi": 0.205746309835365,
              "zar": -0.716773854358066,
              "bits": -2.2570551043747,
              "link": 1.38275060372655,
              "sats": -2.25705510437471
            },
            "market_cap": "$1,105,573,158",
            "market_cap_btc": "18674.7377204264",
            "total_volume": "$160,202,983",
            "total_volume_btc": "2706.13791838869",
            "sparkline": "https://www.coingecko.com/coins/33453/sparkline.svg",
            "content": null
          }
        }
      },
      {
        "item": {
          "id": "altlayer",
          "coin_id": 34608,
          "name": "AltLayer",
          "symbol": "ALT",
          "market_cap_rank": 211,
          "thumb": "https://coin-images.coingecko.com/coins/images/34608/standard/Logomark_200x200.png?1715107868",
          "small": "https://coin-images.coingecko.com/coins/images/34608/small/Logomark_200x200.png?1715107868",
          "large": "https://coin-images.coingecko.com/coins/images/34608/large/Logomark_200x200.png?1715107868",
          "slug": "altlayer",
          "price_btc": 0.00000174795502504,
          "score": 7,
          "data": {
            "price": 0.103483244374171,
            "price_btc": "0.00000174795502504009",
            "price_change_percentage_24h": {
              "aed": 20.0250082936014,
              "ars": 20.0196376634424,
              "aud": 19.2623434665621,
              "bch": 19.5485864107604,
              "bdt": 19.2010831451437,
              "bhd": 19.9442338261435,
              "bmd": 20.0235377993534,
              "bnb": 20.0143452261302,
              "brl": 19.7238344424133,
              "btc": 18.4813150561353,
              "cad": 19.7102161262605,
              "chf": 19.6184434254826,
              "clp": 20.9603570929947,
              "cny": 19.9682837398499,
              "czk": 19.3162448570544,
              "dkk": 19.5869471651187,
              "dot": 21.6367448311915,
              "eos": 18.2254068440346,
              "eth": 20.8890237747041,
              "eur": 19.5524471246894,
              "gbp": 19.4947282049472,
              "gel": 19.8004457216595,
              "hkd": 20.0209507924658,
              "huf": 19.0979934966288,
              "idr": 19.9375933531905,
              "ils": 19.6967909052237,
              "inr": 19.8798886021614,
              "jpy": 19.0792960955062,
              "krw": 19.4616756383887,
              "kwd": 19.8447253580021,
              "lkr": 19.5724344845096,
              "ltc": 17.8239685057488,
              "mmk": 20.0235377993531,
              "mxn": 19.9284293137765,
              "myr": 20.111531595394,
              "ngn": 19.3841197615973,
              "nok": 19.5446203342486,
              "nzd": 19.2527057564129,
              "php": 19.691243457047,
              "pkr": 19.1497427123389,
              "pln": 19.2736516970796,
              "rub": 20.9436493132611,
              "sar": 20.0204354134721,
              "sek": 19.2378768284856,
              "sgd": 19.8880043195081,
              "thb": 18.5792494495662,
              "try": 19.8819885848136,
              "twd": 19.7360247918703,
              "uah": 19.4813667897706,
              "usd": 20.0235377993534,
              "vef": 20.0235377993533,
              "vnd": 20.0246875362389,
              "xag": 16.9118313946291,
              "xau": 17.7523672349273,
              "xdr": 19.2044949541346,
              "xlm": 20.9896985456116,
              "xrp": 21.1639450057021,
              "yfi": 21.4905477233332,
              "zar": 20.3098856309969,
              "bits": 18.4813150561353,
              "link": 22.9257958431166,
              "sats": 18.4813150561353
            },
            "market_cap": "$239,640,834",
            "market_cap_btc": "4048.07718655022",
            "total_volume": "$126,933,433",
            "total_volume_btc": "2144.05659762145",
            "sparkline": "https://www.coingecko.com/coins/34608/sparkline.svg",
            "content": null
          }
        }
      },
      {
        "item": {
          "id": "ethereum",
          "coin_id": 279,
          "name": "Ethereum",
          "symbol": "ETH",
          "market_cap_rank": 2,
          "thumb": "https://coin-images.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
          "small": "https://coin-images.coingecko.com/coins/images/279/small/ethereum.png?1696501628",
          "large": "https://coin-images.coingecko.com/coins/images/279/large/ethereum.png?1696501628",
          "slug": "ethereum",
          "price_btc": 0.0438815283297344,
          "score": 8,
          "data": {
            "price": 2597.780285758,
            "price_btc": "0.0438815283297344",
            "price_change_percentage_24h": {
              "aed": -0.716109770727305,
              "ars": -0.720552320348659,
              "aud": -1.34698105368734,
              "bch": -1.12794246438519,
              "bdt": -1.39765518496721,
              "bhd": -0.782925874090997,
              "bmd": -0.717326153809071,
              "bnb": -0.754682082499131,
              "brl": -0.965238781476001,
              "btc": -1.98872983571988,
              "cad": -0.976503762209749,
              "chf": -1.05241753117862,
              "clp": 0.0576045480244282,
              "cny": -0.763031945097715,
              "czk": -1.30239418122693,
              "dkk": -1.0784710287044,
              "dot": 0.609869121606016,
              "eos": -2.20042472534512,
              "eth": 0,
              "eur": -1.10700923316325,
              "gbp": -1.15475394048165,
              "gel": -0.901866439768206,
              "hkd": -0.71946610873603,
              "huf": -1.48293025799074,
              "idr": -0.788418829243773,
              "ils": -0.987609015945667,
              "inr": -0.836151816296572,
              "jpy": -1.498396624101,
              "krw": -1.18209480416189,
              "kwd": -0.865238618479725,
              "lkr": -1.09047582178089,
              "ltc": -2.53250646675209,
              "mmk": -0.717326153809272,
              "mxn": -0.795999261822964,
              "myr": -0.644538269757595,
              "ngn": -1.24624851068919,
              "nok": -1.11348350226802,
              "nzd": -1.35495330355532,
              "php": -0.992197828257362,
              "pkr": -1.44012365022606,
              "pln": -1.33762696045858,
              "rub": 0.0437839835218577,
              "sar": -0.719892426819163,
              "sek": -1.36721969431411,
              "sgd": -0.829438548772993,
              "thb": -1.91203189070767,
              "try": -0.834414724584405,
              "twd": -0.955155005314455,
              "uah": -1.16580641443306,
              "usd": -0.717326153809071,
              "vef": -0.717326153809204,
              "vnd": -0.716375099088659,
              "xag": -3.29130903874795,
              "xau": -2.59602337046618,
              "xdr": -1.39483295921486,
              "xlm": 0.106751741117622,
              "xrp": 0.230421501461057,
              "yfi": 0.500596990135929,
              "zar": -0.480461128024666,
              "bits": -1.98872983571989,
              "link": 1.68947904736718,
              "sats": -1.98872983571987
            },
            "market_cap": "$312,322,911,494",
            "market_cap_btc": "5275640.4769111",
            "total_volume": "$11,108,809,631",
            "total_volume_btc": "187649.258565752",
            "sparkline": "https://www.coingecko.com/coins/279/sparkline.svg",
            "content": {
              "title": "What is Ethereum?",
              "description": "Ethereum is a Proof-of-Stake blockchain that powers decentralized applications (dApps) through smart contracts, without being controlled by a centralized entity. As the first blockchain to feature smart contracts, it has the largest ecosystem of decentralized applications, ranging from decentralized exchanges to crypto lending and borrowing platforms and more. "
            }
          }
        }
      },
      {
        "item": {
          "id": "synapse-2",
          "coin_id": 18024,
          "name": "Synapse",
          "symbol": "SYN",
          "market_cap_rank": 288,
          "thumb": "https://coin-images.coingecko.com/coins/images/18024/standard/synapse_social_icon.png?1696517540",
          "small": "https://coin-images.coingecko.com/coins/images/18024/small/synapse_social_icon.png?1696517540",
          "large": "https://coin-images.coingecko.com/coins/images/18024/large/synapse_social_icon.png?1696517540",
          "slug": "synapse",
          "price_btc": 0.000010887369286693,
          "score": 9,
          "data": {
            "price": 0.644530725644198,
            "price_btc": "0.0000108873692866937",
            "price_change_percentage_24h": {
              "aed": 56.8699575769336,
              "ars": 56.8629382853947,
              "aud": 55.8731719839647,
              "bch": 56.2192560680209,
              "bdt": 55.7931061363658,
              "bhd": 56.7643871840339,
              "bmd": 56.8680356743773,
              "bnb": 56.8090127761476,
              "brl": 56.4763301993492,
              "btc": 54.8591997878711,
              "cad": 56.4585313696928,
              "chf": 56.3385865358416,
              "clp": 58.0924371965272,
              "cny": 56.7958198745742,
              "czk": 55.9436198761172,
              "dkk": 56.2974216394807,
              "dot": 58.9650230715259,
              "eos": 54.5247188536724,
              "eth": 58.0077682146122,
              "eur": 56.252330870853,
              "gbp": 56.176893554746,
              "gel": 56.5764593998901,
              "hkd": 56.8646545151798,
              "huf": 55.6583703090661,
              "idr": 56.7557082368271,
              "ils": 56.4409849109631,
              "inr": 56.6802894389063,
              "jpy": 55.6339332308052,
              "krw": 56.1336946015651,
              "kwd": 56.6343318780776,
              "lkr": 56.2784538958087,
              "ltc": 54.0000249827303,
              "mmk": 56.868035674377,
              "mxn": 56.7437310456195,
              "myr": 56.9830415656342,
              "ngn": 56.0323308335046,
              "nok": 56.2421014336557,
              "nzd": 55.8605757158723,
              "php": 56.4337345222948,
              "pkr": 55.7260053576984,
              "pln": 55.8879515842058,
              "rub": 58.0706005081964,
              "sar": 56.8639809265497,
              "sek": 55.8411946441445,
              "sgd": 56.690896497002,
              "thb": 54.9803836310154,
              "try": 56.6830340684989,
              "twd": 56.4922627089942,
              "uah": 56.1594304888259,
              "usd": 56.8680356743773,
              "vef": 56.8680356743771,
              "vnd": 56.8695383543519,
              "xag": 52.8011061349356,
              "xau": 53.8996673721649,
              "xdr": 55.7975652931638,
              "xlm": 58.1700904600904,
              "xrp": 58.3654904390177,
              "yfi": 58.7923715508364,
              "zar": 57.2422857814234,
              "bits": 54.8591997878711,
              "link": 60.6708220975582,
              "sats": 54.8591997878711
            },
            "market_cap": "$139,088,537",
            "market_cap_btc": "2349.43095776485",
            "total_volume": "$227,178,760",
            "total_volume_btc": "3837.48820508812",
            "sparkline": "https://www.coingecko.com/coins/18024/sparkline.svg",
            "content": null
          }
        }
      },
      {
        "item": {
          "id": "bitcoin",
          "coin_id": 1,
          "name": "Bitcoin",
          "symbol": "BTC",
          "market_cap_rank": 1,
          "thumb": "https://coin-images.coingecko.com/coins/images/1/standard/bitcoin.png?1696501400",
          "small": "https://coin-images.coingecko.com/coins/images/1/small/bitcoin.png?1696501400",
          "large": "https://coin-images.coingecko.com/coins/images/1/large/bitcoin.png?1696501400",
          "slug": "bitcoin",
          "price_btc": 1,
          "score": 10,
          "data": {
            "price": 59202.5998939096,
            "price_btc": "1.0",
            "price_change_percentage_24h": {
              "aed": 1.32187322273165,
              "ars": 1.3173394816732,
              "aud": 0.678052155644437,
              "bch": 0.897435809309831,
              "bdt": 0.626337845363579,
              "bhd": 1.25368559693795,
              "bmd": 1.3206318711673,
              "bnb": 1.27491682211553,
              "brl": 1.06763038449345,
              "btc": 0,
              "cad": 1.05613416947673,
              "chf": 0.978662131892855,
              "clp": 2.11146943954083,
              "cny": 1.27398788512099,
              "czk": 0.723554255012477,
              "dkk": 0.952073838794992,
              "dot": 2.66737354042785,
              "eos": -0.236405138075151,
              "eth": 2.04494140491999,
              "eur": 0.922949835620687,
              "gbp": 0.8742250810729,
              "gel": 1.13230355913907,
              "hkd": 1.31844798976109,
              "huf": 0.539312345878871,
              "idr": 1.2480798888586,
              "ils": 1.04480096014606,
              "inr": 1.19936709515637,
              "jpy": 0.523528504385658,
              "krw": 0.846322996281423,
              "kwd": 1.1696832332889,
              "lkr": 0.93982262543763,
              "ltc": -0.527040223134364,
              "mmk": 1.32063187116725,
              "mxn": 1.24034385406966,
              "myr": 1.39491385934274,
              "ngn": 0.780852417877228,
              "nok": 0.916342670330016,
              "nzd": 0.669916260802246,
              "php": 1.04011795408895,
              "pkr": 0.582997637370128,
              "pln": 0.687598258703435,
              "rub": 2.09736518273583,
              "sar": 1.31801292072175,
              "sek": 0.657398079992735,
              "sgd": 1.20621816474431,
              "thb": 0.101402619241673,
              "try": 1.20113984384495,
              "twd": 1.07792114855847,
              "uah": 0.862945734927942,
              "usd": 1.3206318711673,
              "vef": 1.32063187116729,
              "vnd": 1.32160244802137,
              "xag": -1.30618670879578,
              "xau": -0.596629033602133,
              "xdr": 0.62921800244872,
              "xlm": 2.15396724651,
              "xrp": 2.27177748287341,
              "yfi": 2.5542527731768,
              "zar": 1.56235898377352,
              "bits": 0.0156323925383066,
              "link": 3.76906183893964,
              "sats": 0.0156323925383043
            },
            "market_cap": "$1,168,829,203,437",
            "market_cap_btc": "19741087.0",
            "total_volume": "$23,339,101,970",
            "total_volume_btc": "394212.97379639",
            "sparkline": "https://www.coingecko.com/coins/1/sparkline.svg",
            "content": {
              "title": "About Bitcoin (BTC)",
              "description": "Bitcoin (BTC) is the first cryptocurrency built on blockchain technology, also known as a decentralized digital currency that is based on cryptography. Unlike government-issued or fiat currencies such as US Dollars or Euro which are controlled by central banks, Bitcoin can operate without the need of a central authority like a central bank or a company. The decentralized nature allows it to operate on a peer-to-peer network whereby users are able to send funds to each other without going through intermediaries."
            }
          }
        }
      },
      {
        "item": {
          "id": "sundog",
          "coin_id": 39752,
          "name": "Sundog",
          "symbol": "SUNDOG",
          "market_cap_rank": 528,
          "thumb": "https://coin-images.coingecko.com/coins/images/39752/standard/sundog.png?1723794864",
          "small": "https://coin-images.coingecko.com/coins/images/39752/small/sundog.png?1723794864",
          "large": "https://coin-images.coingecko.com/coins/images/39752/large/sundog.png?1723794864",
          "slug": "sundog",
          "price_btc": 9.09663127106765e-7,
          "score": 11,
          "data": {
            "price": 0.0538542984985571,
            "price_btc": "0.000000909663127106765",
            "price_change_percentage_24h": {
              "aed": -8.9609699808418,
              "ars": -8.96173406406255,
              "aud": -9.52062200965091,
              "bch": -9.35332746747995,
              "bdt": -9.58591762625676,
              "bhd": -9.02223745561446,
              "bmd": -8.96208535147925,
              "bnb": -9.00147926078058,
              "brl": -9.18941050060613,
              "btc": -10.1440691088654,
              "cad": -9.19213150942896,
              "chf": -9.24146426716253,
              "clp": -8.25150743935168,
              "cny": -9.00653435772456,
              "czk": -9.49029506323099,
              "dkk": -9.29177013514612,
              "dot": -7.80007903486764,
              "eos": -10.3333836914292,
              "eth": -8.34560061973762,
              "eur": -9.31502342324219,
              "gbp": -9.36026763423156,
              "gel": -9.13130080621622,
              "hkd": -8.96296136462987,
              "huf": -9.68738832193057,
              "idr": -9.02453592726828,
              "ils": -9.20839487991163,
              "inr": -9.07300016580231,
              "jpy": -9.63991018112646,
              "krw": -9.38393606373945,
              "kwd": -9.0977146794618,
              "lkr": -9.30424744590935,
              "ltc": -10.5375919808243,
              "mmk": -8.96208535147882,
              "mxn": -9.01279621813564,
              "myr": -8.86450437560651,
              "ngn": -9.44708425930397,
              "nok": -9.32950604793036,
              "nzd": -9.53114386111982,
              "php": -9.2093731921181,
              "pkr": -9.62485937072922,
              "pln": -9.52747996717209,
              "rub": -8.26494357165493,
              "sar": -8.96443851275295,
              "sek": -9.60350247163335,
              "sgd": -9.06178620044756,
              "thb": -10.0171156948725,
              "try": -9.0710558418022,
              "twd": -9.18537060076343,
              "uah": -9.37332233882582,
              "usd": -8.96208535147925,
              "vef": -8.96208535147925,
              "vnd": -8.9612132754652,
              "xag": -11.3160522776575,
              "xau": -10.62979127797,
              "xdr": -9.58332976739658,
              "xlm": -8.23338164437677,
              "xrp": -8.06236087673331,
              "yfi": -7.87999456200009,
              "zar": -8.71882852886971,
              "bits": -10.1440691088654,
              "link": -6.79943275720158,
              "sats": -10.1440691088654
            },
            "market_cap": "$53,854,298",
            "market_cap_btc": "909.663127106765",
            "total_volume": "$23,478,110",
            "total_volume_btc": "396.573197024135",
            "sparkline": "https://www.coingecko.com/coins/39752/sparkline.svg",
            "content": null
          }
        }
      },
      {
        "item": {
          "id": "neiro-on-eth",
          "coin_id": 39438,
          "name": "Neiro on ETH",
          "symbol": "NEIRO",
          "market_cap_rank": 249,
          "thumb": "https://coin-images.coingecko.com/coins/images/39438/standard/Neiro.png?1722915026",
          "small": "https://coin-images.coingecko.com/coins/images/39438/small/Neiro.png?1722915026",
          "large": "https://coin-images.coingecko.com/coins/images/39438/large/Neiro.png?1722915026",
          "slug": "neiro-on-eth",
          "price_btc": 0.000002891298682842,
          "score": 12,
          "data": {
            "price": 0.171164474083189,
            "price_btc": "0.00000289129868284221",
            "price_change_percentage_24h": {
              "aed": -16.8518314382464,
              "ars": -16.8525292941043,
              "aud": -17.3629752983855,
              "bch": -17.2202893684669,
              "bdt": -17.4226113790286,
              "bhd": -16.9077885186176,
              "bmd": -16.8528501334776,
              "bnb": -16.9089761576134,
              "brl": -17.0604717416379,
              "btc": -17.9240704790491,
              "cad": -17.062956905421,
              "chf": -17.1080137173708,
              "clp": -16.2038619797001,
              "cny": -16.8934464959709,
              "czk": -17.3352769578789,
              "dkk": -17.1539592946671,
              "dot": -15.7802693885683,
              "eos": -18.096993625932,
              "eth": -16.2825540976896,
              "eur": -17.1751970903447,
              "gbp": -17.2165197327432,
              "gel": -17.0073987391773,
              "hkd": -16.8536502176733,
              "huf": -17.5152870424904,
              "idr": -16.9098877688896,
              "ils": -17.0778106387046,
              "inr": -16.954151341052,
              "jpy": -17.4719240974576,
              "krw": -17.2381366872066,
              "kwd": -16.9767237097086,
              "lkr": -17.1653551271838,
              "ltc": -18.3026165621591,
              "mmk": -16.8528501334773,
              "mxn": -16.8991656059488,
              "myr": -16.7637270460656,
              "ngn": -17.2958114757899,
              "nok": -17.1884244250225,
              "nzd": -17.3725851621916,
              "php": -17.0787041551248,
              "pkr": -17.4581778249014,
              "pln": -17.3692388384062,
              "rub": -16.2161335273355,
              "sar": -16.8549993331143,
              "sek": -17.4386720475923,
              "sgd": -16.943909352738,
              "thb": -17.8164351015048,
              "try": -16.9523755424777,
              "twd": -17.0567820024889,
              "uah": -17.2284429131404,
              "usd": -16.8528501334776,
              "vef": -16.8528501334775,
              "vnd": -16.8520536451646,
              "xag": -19.0027855922088,
              "xau": -18.3760066681872,
              "xdr": -17.4202478243568,
              "xlm": -16.1616710184058,
              "xrp": -16.0226029137009,
              "yfi": -15.8560264323647,
              "zar": -16.6306777390022,
              "bits": -17.9240704790491,
              "link": -14.86768173317,
              "sats": -17.9240704790491
            },
            "market_cap": "$172,218,698",
            "market_cap_btc": "2909.10567010496",
            "total_volume": "$40,720,655",
            "total_volume_btc": "687.850542995563",
            "sparkline": "https://www.coingecko.com/coins/39438/sparkline.svg",
            "content": null
          }
        }
      },
      {
        "item": {
          "id": "fantom",
          "coin_id": 4001,
          "name": "Fantom",
          "symbol": "FTM",
          "market_cap_rank": 69,
          "thumb": "https://coin-images.coingecko.com/coins/images/4001/standard/Fantom_round.png?1696504642",
          "small": "https://coin-images.coingecko.com/coins/images/4001/small/Fantom_round.png?1696504642",
          "large": "https://coin-images.coingecko.com/coins/images/4001/large/Fantom_round.png?1696504642",
          "slug": "fantom",
          "price_btc": 0.000006501524140436,
          "score": 13,
          "data": {
            "price": 0.38488931179639,
            "price_btc": "0.00000650152414043634",
            "price_change_percentage_24h": {
              "aed": -2.97104028133741,
              "ars": -2.97185463755346,
              "aud": -3.56751471816215,
              "bch": -3.40100873750169,
              "bdt": -3.63710647185426,
              "bhd": -3.03633886086392,
              "bmd": -2.97222903799029,
              "bnb": -3.03772476482641,
              "brl": -3.21451108705252,
              "btc": -4.22227937052501,
              "cad": -3.21741112515045,
              "chf": -3.2699897407393,
              "clp": -2.21489852163624,
              "cny": -3.01960257476468,
              "czk": -3.53519240473324,
              "dkk": -3.32360549254542,
              "dot": -1.72059120046504,
              "eos": -4.42407038220395,
              "eth": -2.30672753577634,
              "eur": -3.34838873500229,
              "gbp": -3.39660979716287,
              "gel": -3.15257805464811,
              "hkd": -2.97316268858702,
              "huf": -3.74525345058489,
              "idr": -3.03878856088257,
              "ils": -3.23474454708111,
              "inr": -3.09044151289158,
              "jpy": -3.69465147678729,
              "krw": -3.42183549533691,
              "kwd": -3.11678212251675,
              "lkr": -3.33690375026234,
              "ltc": -4.66402010017746,
              "mmk": -2.97222903798998,
              "mxn": -3.02627643543142,
              "myr": -2.86822770388899,
              "ngn": -3.489138538026,
              "nok": -3.36382424664174,
              "nzd": -3.57872885678081,
              "php": -3.23578722751827,
              "pkr": -3.678610395556,
              "pln": -3.57482389625795,
              "rub": -2.22921868673188,
              "sar": -2.97473702594225,
              "sek": -3.65584831536181,
              "sgd": -3.07848972262665,
              "thb": -4.09667529658443,
              "try": -3.08836926173652,
              "twd": -3.21020538125702,
              "uah": -3.41052343844306,
              "usd": -2.97222903799029,
              "vef": -2.9722290379902,
              "vnd": -2.9712995835745,
              "xag": -5.48107565038472,
              "xau": -4.74966198219033,
              "xdr": -3.63434834434511,
              "xlm": -2.16566418304856,
              "xrp": -2.00337998890501,
              "yfi": -1.8089951578591,
              "zar": -2.71296708806365,
              "bits": -4.22227937052501,
              "link": -0.655655767886943,
              "sats": -4.22227937052502
            },
            "market_cap": "$1,081,654,866",
            "market_cap_btc": "18270.9048361486",
            "total_volume": "$194,949,061",
            "total_volume_btc": "3293.06630618606",
            "sparkline": "https://www.coingecko.com/coins/4001/sparkline.svg",
            "content": {
              "title": "What is Fantom?",
              "description": "Fantom is a Layer 1, EVM-compatible blockchain. It aimed to create a secure and efficient smart contract platform for projects to develop decentralized applications (dApps) and execute peer-to-peer financial transactions."
            }
          }
        }
      },
      {
        "item": {
          "id": "bittensor",
          "coin_id": 28452,
          "name": "Bittensor",
          "symbol": "TAO",
          "market_cap_rank": 44,
          "thumb": "https://coin-images.coingecko.com/coins/images/28452/standard/ARUsPeNQ_400x400.jpeg?1696527447",
          "small": "https://coin-images.coingecko.com/coins/images/28452/small/ARUsPeNQ_400x400.jpeg?1696527447",
          "large": "https://coin-images.coingecko.com/coins/images/28452/large/ARUsPeNQ_400x400.jpeg?1696527447",
          "slug": "bittensor",
          "price_btc": 0.00459163670837792,
          "score": 14,
          "data": {
            "price": 271.824245289661,
            "price_btc": "0.00459163670837792",
            "price_change_percentage_24h": {
              "aed": -0.592795959028083,
              "ars": -0.593630275656486,
              "aud": -1.2038903809263,
              "bch": -1.03330322796134,
              "bdt": -1.27518787477656,
              "bhd": -0.65969505015919,
              "bmd": -0.594013852896966,
              "bnb": -0.661114923549377,
              "brl": -0.842234396087686,
              "btc": -1.87470375030566,
              "cad": -0.845205516047768,
              "chf": -0.899072868466961,
              "clp": 0.181879338001384,
              "cny": -0.642548546558842,
              "czk": -1.17077582610245,
              "dkk": -0.954002778001746,
              "dot": 0.688302460305149,
              "eos": -2.08144079386221,
              "eth": 0.0877995336628207,
              "eur": -0.979393474210905,
              "gbp": -1.02879646666975,
              "gel": -0.778783343876528,
              "hkd": -0.594970387890663,
              "huf": -1.38598560786841,
              "idr": -0.66220479395424,
              "ils": -0.862963791675841,
              "inr": -0.715123794163714,
              "jpy": -1.33414334604518,
              "krw": -1.05464046347993,
              "kwd": -0.742110029646068,
              "lkr": -0.967626984858022,
              "ltc": -2.32727183897829,
              "mmk": -0.594013852896657,
              "mxn": -0.649385987988319,
              "myr": -0.487463376940957,
              "ngn": -1.12359314849859,
              "nok": -0.995207320504514,
              "nzd": -1.21537938553934,
              "php": -0.864032028903841,
              "pkr": -1.31770908724635,
              "pln": -1.21137871183747,
              "rub": 0.167208176137001,
              "sar": -0.596583313301341,
              "sek": -1.29438909333089,
              "sgd": -0.702879057572042,
              "thb": -1.74602103699211,
              "try": -0.713000750754929,
              "twd": -0.837823154580049,
              "uah": -1.0430511405496,
              "usd": -0.594013852896966,
              "vef": -0.594013852896903,
              "vnd": -0.593061616935421,
              "xag": -3.16435396402388,
              "xau": -2.41501286046703,
              "xdr": -1.27236214372814,
              "xlm": 0.232320442966323,
              "xrp": 0.398582330619547,
              "yfi": 0.597731663130968,
              "zar": -0.32839722018087,
              "bits": -1.87470375030565,
              "link": 1.77934016846674,
              "sats": -1.87470375030565
            },
            "market_cap": "$1,964,737,456",
            "market_cap_btc": "33189.468344401",
            "total_volume": "$79,998,508",
            "total_volume_btc": "1351.32937137742",
            "sparkline": "https://www.coingecko.com/coins/28452/sparkline.svg",
            "content": {
              "title": "What is Bittensor?",
              "description": "Bittensor is an open-source protocol that utilizes blockchain technology to create a decentralized machine learning network. This network enables machine learning models to train collaboratively and be rewarded in TAO according to the informational value they offer the collective. Additionally, Bittensor's TAO grants external access to users, allowing them to extract information from the network while tuning its activities to meet their needs. The ultimate vision of Bittensor is to create a market for artificial intelligence, allowing producers and consumers of this commodity to interact in a trustless, open, and transparent context. Bittensor provides a novel strategy for developing and distributing artificial intelligence technology, promoting open access/ownership, decentralized governance, and global access to computing power and innovation within an incentivized framework. The Bittensor network operates using two types of nodes, servers and validators, with assessments based on the value of their responses. Nodes that add value to the network are rewarded with more stake (TAO), while low-value nodes are weakened and eventually de-registered."
            }
          }
        }
      }
    ],
    "nfts": [
      {
        "id": "cyberkongz",
        "name": "CyberKongz",
        "symbol": "KONGZ",
        "thumb": "https://coin-images.coingecko.com/nft_contracts/images/90/standard/cyberkongz.gif?1707287194",
        "nft_contract_id": 90,
        "native_currency_symbol": "eth",
        "floor_price_in_native_currency": 6.89,
        "floor_price_24h_percentage_change": 48.2845531030821,
        "data": {
          "floor_price": "6,89 ETH",
          "floor_price_in_usd_24h_percentage_change": "48.2845531030821",
          "h24_volume": "4,60 ETH",
          "h24_average_sale_price": "4,60 ETH",
          "sparkline": "https://www.coingecko.com/nft/90/sparkline.svg",
          "content": null
        }
      },
      {
        "id": "natcats",
        "name": "Natcats",
        "symbol": "DMTNATCATS",
        "thumb": "https://coin-images.coingecko.com/nft_contracts/images/4171/standard/natcats.png?1709517703",
        "nft_contract_id": 4171,
        "native_currency_symbol": "btc",
        "floor_price_in_native_currency": 0.01769999,
        "floor_price_24h_percentage_change": 19.3475447846939,
        "data": {
          "floor_price": "0,018 BTC",
          "floor_price_in_usd_24h_percentage_change": "19.3475447846939",
          "h24_volume": "1,04 BTC",
          "h24_average_sale_price": "0,023 BTC",
          "sparkline": "https://www.coingecko.com/nft/4171/sparkline.svg",
          "content": null
        }
      },
      {
        "id": "winds-of-yawanawa-by-yawanawa-and-refik-anadol",
        "name": "Winds of Yawanawa by Yawanawa and Refik Anadol",
        "symbol": "YAWANAWA",
        "thumb": "https://coin-images.coingecko.com/nft_contracts/images/3438/standard/winds-of-yawanawa-by-yawanawa-and-refik-anadol.png?1707290021",
        "nft_contract_id": 3438,
        "native_currency_symbol": "eth",
        "floor_price_in_native_currency": 4.6,
        "floor_price_24h_percentage_change": 14.1583474489645,
        "data": {
          "floor_price": "4,60 ETH",
          "floor_price_in_usd_24h_percentage_change": "14.1583474489645",
          "h24_volume": "4,00 ETH",
          "h24_average_sale_price": "4,00 ETH",
          "sparkline": "https://www.coingecko.com/nft/3438/sparkline.svg",
          "content": null
        }
      },
      {
        "id": "terraforms-by-mathcastles",
        "name": "Terraforms by Mathcastles",
        "symbol": "TERRAFORMS",
        "thumb": "https://coin-images.coingecko.com/nft_contracts/images/488/standard/terraforms-by-mathcastles.png?1707287339",
        "nft_contract_id": 488,
        "native_currency_symbol": "eth",
        "floor_price_in_native_currency": 0.65,
        "floor_price_24h_percentage_change": 8.56019019974807,
        "data": {
          "floor_price": "0,65 ETH",
          "floor_price_in_usd_24h_percentage_change": "8.56019019974807",
          "h24_volume": "11,66 ETH",
          "h24_average_sale_price": "0,73 ETH",
          "sparkline": "https://www.coingecko.com/nft/488/sparkline.svg",
          "content": null
        }
      },
      {
        "id": "o-p-i-u-m",
        "name": "O.P.I.U.M.",
        "symbol": "OPIUM",
        "thumb": "https://coin-images.coingecko.com/nft_contracts/images/3874/standard/o-p-i-u-m.jpg?1707290229",
        "nft_contract_id": 3874,
        "native_currency_symbol": "btc",
        "floor_price_in_native_currency": 0.121,
        "floor_price_24h_percentage_change": 5.83257232456555,
        "data": {
          "floor_price": "0,12 BTC",
          "floor_price_in_usd_24h_percentage_change": "5.83257232456555",
          "h24_volume": "0,24 BTC",
          "h24_average_sale_price": "0,12 BTC",
          "sparkline": "https://www.coingecko.com/nft/3874/sparkline.svg",
          "content": null
        }
      },
      {
        "id": "y00ts-solana",
        "name": "y00ts (Solana)",
        "symbol": "Y00TSREVEAL",
        "thumb": "https://coin-images.coingecko.com/nft_contracts/images/2432/standard/y00ts.jpg?1707288354",
        "nft_contract_id": 2432,
        "native_currency_symbol": "sol",
        "floor_price_in_native_currency": 7.1273917,
        "floor_price_24h_percentage_change": 5.45316084873094,
        "data": {
          "floor_price": "7,13 SOL",
          "floor_price_in_usd_24h_percentage_change": "5.45316084873094",
          "h24_volume": "520,14 SOL",
          "h24_average_sale_price": "6,67 SOL",
          "sparkline": "https://www.coingecko.com/nft/2432/sparkline.svg",
          "content": null
        }
      },
      {
        "id": "mfers",
        "name": "mfers",
        "symbol": "MFER",
        "thumb": "https://coin-images.coingecko.com/nft_contracts/images/312/standard/mfers.png?1707287263",
        "nft_contract_id": 312,
        "native_currency_symbol": "eth",
        "floor_price_in_native_currency": 0.293951,
        "floor_price_24h_percentage_change": 6.4288133181919,
        "data": {
          "floor_price": "0,29 ETH",
          "floor_price_in_usd_24h_percentage_change": "6.4288133181919",
          "h24_volume": "4,56 ETH",
          "h24_average_sale_price": "0,29 ETH",
          "sparkline": "https://www.coingecko.com/nft/312/sparkline.svg",
          "content": null
        }
      }
    ],
    "categories": [
      {
        "id": 288,
        "name": "Cat-Themed",
        "market_cap_1h_change": 1.98863142903529,
        "slug": "cat-themed-coins",
        "coins_count": 226,
        "data": {
          "market_cap": 2043323139.4426,
          "market_cap_btc": 34977.0046652467,
          "total_volume": 250835448.644612,
          "total_volume_btc": 4293.72745215677,
          "market_cap_change_percentage_24h": {
            "aed": -6.77334399444092,
            "ars": -6.72484606513439,
            "aud": -6.53612699112433,
            "bch": -2.28656842799156,
            "bdt": -6.63048495122932,
            "bhd": -6.76441228209826,
            "bmd": -6.77232871895727,
            "bnb": -6.06885992201242,
            "brl": -6.54682749838451,
            "btc": -3.27363613484685,
            "cad": -6.80122482325753,
            "chf": -6.67221624031848,
            "clp": -6.49825643338626,
            "cny": -6.77884458242193,
            "czk": -6.88571978857159,
            "dkk": -6.91805516761015,
            "dot": -3.67165229857907,
            "eos": -5.53047760784038,
            "eth": -3.9137276551394,
            "eur": -6.91430405885748,
            "gbp": -6.62768202735162,
            "gel": -6.94529285862526,
            "hkd": -6.74897601439518,
            "huf": -6.66391166655198,
            "idr": -7.03111385761288,
            "ils": -6.96090684948233,
            "inr": -6.69701486074268,
            "jpy": -6.49152880196547,
            "krw": -7.11228744876677,
            "kwd": -6.80704879620585,
            "lkr": -6.64017409116144,
            "ltc": -8.31448505258203,
            "mmk": -6.77232871895713,
            "mxn": -7.78362549802723,
            "myr": -7.21168713388153,
            "ngn": -6.77232871895729,
            "nok": -7.26817480303789,
            "nzd": -5.58565459756171,
            "php": -6.76986654901968,
            "pkr": -6.67754163667091,
            "pln": -7.03757774882193,
            "rub": -8.31551395043486,
            "sar": -6.81685416705123,
            "sek": -6.74831131446438,
            "sgd": -6.72646432033045,
            "thb": -6.93329810651938,
            "try": -6.37485931505387,
            "twd": -6.77449499312576,
            "uah": -6.8634179195371,
            "usd": -6.77232871895727,
            "vef": -6.77232871895724,
            "vnd": -7.05253720429448,
            "xag": -7.50121040851085,
            "xau": -6.50358826513143,
            "xdr": -6.64319676675919,
            "xlm": -4.2058601880226,
            "xrp": -5.16663719736495,
            "yfi": -5.94352821441719,
            "zar": -7.17327510570032,
            "bits": -3.27363613484685,
            "link": -5.10362103780651,
            "sats": -3.27363613484684
          },
          "sparkline": "https://www.coingecko.com/categories/32462910/sparkline.svg"
        }
      },
      {
        "id": 251,
        "name": "Solana Meme",
        "market_cap_1h_change": 1.51487019846654,
        "slug": "solana-meme-coins",
        "coins_count": 442,
        "data": {
          "market_cap": 6368399954.5651,
          "market_cap_btc": 109013.823027003,
          "total_volume": 1440709579.81948,
          "total_volume_btc": 24661.9653740755,
          "market_cap_change_percentage_24h": {
            "aed": -4.60147584687639,
            "ars": -4.55055246419539,
            "aud": -4.32661432687432,
            "bch": -0.00226937508014912,
            "bdt": -4.45528866749844,
            "bhd": -4.57638345989069,
            "bmd": -4.60043691888213,
            "bnb": -3.80733539978325,
            "brl": -4.37843111921011,
            "btc": -1.01241229065193,
            "cad": -4.6136574791761,
            "chf": -4.47968956376581,
            "clp": -4.31997966904587,
            "cny": -4.60710458009358,
            "czk": -4.72360841041409,
            "dkk": -4.74523518294181,
            "dot": -1.41019051143185,
            "eos": -3.3071778561052,
            "eth": -1.66591683325122,
            "eur": -4.73670372619747,
            "gbp": -4.4689860709025,
            "gel": -4.77743054240939,
            "hkd": -4.56828418056331,
            "huf": -4.47352996638831,
            "idr": -4.8558868091694,
            "ils": -4.74964554934907,
            "inr": -4.52616627386681,
            "jpy": -4.32322206828646,
            "krw": -4.95940176895991,
            "kwd": -4.6359658573839,
            "lkr": -4.46520353186741,
            "ltc": -6.14624606813568,
            "mmk": -4.600436918882,
            "mxn": -5.62432697307328,
            "myr": -4.69713655484683,
            "ngn": -4.6004369188821,
            "nok": -5.09497358103991,
            "nzd": -3.39411957126671,
            "php": -4.50736344141483,
            "pkr": -4.50344161575285,
            "pln": -4.8927782992994,
            "rub": -5.7938090911841,
            "sar": -4.64345966079372,
            "sek": -4.61976377770198,
            "sgd": -4.56031271702047,
            "thb": -4.68627200134554,
            "try": -4.19933571612029,
            "twd": -4.59895810758556,
            "uah": -4.69364819207309,
            "usd": -4.60043691888213,
            "vef": -4.60043691888224,
            "vnd": -4.88717332134234,
            "xag": -5.38290261367247,
            "xau": -4.31129746987695,
            "xdr": -4.46829662565991,
            "xlm": -1.98786186522026,
            "xrp": -2.94966704956267,
            "yfi": -3.7411651358758,
            "zar": -5.00048913501334,
            "bits": -1.01241229065193,
            "link": -2.8521374531409,
            "sats": -1.01241229065193
          },
          "sparkline": "https://www.coingecko.com/categories/32463057/sparkline.svg"
        }
      },
      {
        "id": 119,
        "name": "Play To Earn",
        "market_cap_1h_change": 0.892317077490032,
        "slug": "play-to-earn",
        "coins_count": 280,
        "data": {
          "market_cap": 8630793698.6052,
          "market_cap_btc": 147739.388662356,
          "total_volume": 990321978.996336,
          "total_volume_btc": 16952.0404339474,
          "market_cap_change_percentage_24h": {
            "aed": -2.37331518157763,
            "ars": -2.32297886524393,
            "aud": -2.09449650272768,
            "bch": 2.33197405219131,
            "bdt": -2.22371360427528,
            "bhd": -2.34763672819105,
            "bmd": -2.3722519880247,
            "bnb": -1.56191349179019,
            "brl": -2.13969422715752,
            "btc": 1.29825140689424,
            "cad": -2.39254600437974,
            "chf": -2.26978682348356,
            "clp": -2.08524428242477,
            "cny": -2.37907538141942,
            "czk": -2.49532058029739,
            "dkk": -2.52043221002605,
            "dot": 0.881422532049299,
            "eos": -1.06526238377733,
            "eth": 0.636952932073055,
            "eur": -2.51728376377251,
            "gbp": -2.21826242831804,
            "gel": -2.55337953535698,
            "hkd": -2.34617971598184,
            "huf": -2.24134004006595,
            "idr": -2.64329937066714,
            "ils": -2.56973046147054,
            "inr": -2.29338353329924,
            "jpy": -2.10849376418223,
            "krw": -2.73924283054167,
            "kwd": -2.40861075260601,
            "lkr": -2.23386004361848,
            "ltc": -3.97709292766312,
            "mmk": -2.3722519880244,
            "mxn": -3.42832686028419,
            "myr": -2.47121017386881,
            "ngn": -2.37225198802473,
            "nok": -2.85484556069464,
            "nzd": -1.12957027663997,
            "php": -2.26241326686647,
            "pkr": -2.27299122931291,
            "pln": -2.65880581667569,
            "rub": -3.98519546279574,
            "sar": -2.41627958385925,
            "sek": -2.34312569611229,
            "sgd": -2.32548354769203,
            "thb": -2.45730608519995,
            "try": -1.96179126566751,
            "twd": -2.37452050407539,
            "uah": -2.46764033569674,
            "usd": -2.3722519880247,
            "vef": -2.37225198802472,
            "vnd": -2.66568550429131,
            "xag": -3.15400105417996,
            "xau": -2.07876493045254,
            "xdr": -2.23702538077114,
            "xlm": 0.307514912337247,
            "xrp": -0.684224620136069,
            "yfi": -1.49783632243088,
            "zar": -2.78449435789463,
            "bits": 1.29825140689424,
            "link": -0.626370780176374,
            "sats": 1.29825140689423
          },
          "sparkline": "https://www.coingecko.com/categories/32462982/sparkline.svg"
        }
      },
      {
        "id": 229,
        "name": "Frog-Themed",
        "market_cap_1h_change": 0.880724339626431,
        "slug": "frog-themed-coins",
        "coins_count": 74,
        "data": {
          "market_cap": 4860453493.56251,
          "market_cap_btc": 83200.9014443857,
          "total_volume": 882670144.830501,
          "total_volume_btc": 15109.4855295316,
          "market_cap_change_percentage_24h": {
            "aed": -4.72347680285678,
            "ars": -4.6726185438391,
            "aud": -4.44896679110391,
            "bch": -0.130152052935082,
            "bdt": -4.57747657580113,
            "bhd": -4.69841650541573,
            "bmd": -4.72243920350161,
            "bnb": -3.93035194688088,
            "brl": -4.50071731723426,
            "btc": -1.13900314137985,
            "cad": -4.73564285660536,
            "chf": -4.60184626684075,
            "clp": -4.44234061804612,
            "cny": -4.72909833773584,
            "czk": -4.84545317645716,
            "dkk": -4.86705229146136,
            "dot": -1.53627266117476,
            "eos": -3.43083403257876,
            "eth": -1.79167194590413,
            "eur": -4.85853174521997,
            "gbp": -4.59115646220141,
            "gel": -4.89920647770674,
            "hkd": -4.69032758389867,
            "huf": -4.59569454670011,
            "idr": -4.97756241021491,
            "ils": -4.87145701764598,
            "inr": -4.64826353993149,
            "jpy": -4.44557887072561,
            "krw": -5.08094498929865,
            "kwd": -4.75792270561428,
            "lkr": -4.5873787604883,
            "ltc": -6.26627148575803,
            "mmk": -4.72243920350147,
            "mxn": -5.74501984995781,
            "myr": -4.81901517457593,
            "ngn": -4.72243920350159,
            "nok": -5.21634342458039,
            "nzd": -3.51766456182353,
            "php": -4.62948475359085,
            "pkr": -4.62556794337837,
            "pln": -5.01440672144877,
            "rub": -5.91428522487877,
            "sar": -4.76540692553015,
            "sek": -4.74174134605593,
            "sgd": -4.68236631470825,
            "thb": -4.8081645152719,
            "try": -4.32185095133952,
            "twd": -4.72096228339143,
            "uah": -4.81553127291516,
            "usd": -4.72243920350161,
            "vef": -4.72243920350171,
            "vnd": -5.00880891145028,
            "xag": -5.5039042374994,
            "xau": -4.43366952215796,
            "xdr": -4.59046789865986,
            "xlm": -2.11320525660155,
            "xrp": -3.07378043080495,
            "yfi": -3.86426630519924,
            "zar": -5.12197981053567,
            "bits": -1.13900314137987,
            "link": -2.97637556067329,
            "sats": -1.13900314137986
          },
          "sparkline": "https://www.coingecko.com/categories/32463036/sparkline.svg"
        }
      },
      {
        "id": 113,
        "name": "Meme",
        "market_cap_1h_change": 0.846247863944387,
        "slug": "meme-token",
        "coins_count": 2056,
        "data": {
          "market_cap": 40417461344.003,
          "market_cap_btc": 691854.218600128,
          "total_volume": 3843933689.54663,
          "total_volume_btc": 65799.3265954244,
          "market_cap_change_percentage_24h": {
            "aed": -3.71315774622489,
            "ars": -3.66306786132657,
            "aud": -3.46815404066566,
            "bch": 0.920897251618642,
            "bdt": -3.56560932233397,
            "bhd": -3.70393284838728,
            "bmd": -3.71210914409191,
            "bnb": -2.98554882342736,
            "brl": -3.47920579451742,
            "btc": -0.0985711778625343,
            "cad": -3.74195376960697,
            "chf": -3.60871045050634,
            "clp": -3.42904037323162,
            "cny": -3.71883889226105,
            "czk": -3.82922230143062,
            "dkk": -3.86261909666175,
            "dot": -0.509652312956044,
            "eos": -2.42949398698859,
            "eth": -0.759673848312428,
            "eur": -3.85874485690867,
            "gbp": -3.56271439184065,
            "gel": -3.89075087109731,
            "hkd": -3.68798988167587,
            "huf": -3.60013327721167,
            "idr": -3.9793889639523,
            "ils": -3.90687739476579,
            "inr": -3.63432309130248,
            "jpy": -3.42209190577489,
            "krw": -4.06322709675138,
            "kwd": -3.74796891579576,
            "lkr": -3.57561651049444,
            "ltc": -5.30488711113098,
            "mmk": -3.71210914409175,
            "mxn": -4.75660197060982,
            "myr": -4.16588959920293,
            "ngn": -3.71210914409191,
            "nok": -4.22423148898408,
            "nzd": -2.4864821739833,
            "php": -3.7095661528645,
            "pkr": -3.61421065420872,
            "pln": -3.98606503386471,
            "rub": -5.30594978279054,
            "sar": -3.75809615017572,
            "sek": -3.6873033628182,
            "sgd": -3.66473923611856,
            "thb": -3.87836238851756,
            "try": -3.30159271634331,
            "twd": -3.7143465267041,
            "uah": -3.80618836845804,
            "usd": -3.71210914409191,
            "vef": -3.71210914409187,
            "vnd": -4.00151553687536,
            "xag": -4.4649165413617,
            "xau": -3.43454722357,
            "xdr": -3.57873840610815,
            "xlm": -1.06139569822242,
            "xrp": -2.05371043204795,
            "yfi": -2.85610307394238,
            "zar": -4.12621668743494,
            "bits": -0.0985711778625386,
            "link": -1.98862575267813,
            "sats": -0.0985711778625344
          },
          "sparkline": "https://www.coingecko.com/categories/32462925/sparkline.svg"
        }
      },
      {
        "id": 61,
        "name": "Gaming (GameFi)",
        "market_cap_1h_change": 0.830041736245723,
        "slug": "gaming",
        "coins_count": 529,
        "data": {
          "market_cap": 13305638076.0513,
          "market_cap_btc": 227762.000085354,
          "total_volume": 1666129182.83218,
          "total_volume_btc": 28520.3094292379,
          "market_cap_change_percentage_24h": {
            "aed": -2.85708312900135,
            "ars": -2.80654790102151,
            "aud": -2.6099011252599,
            "bch": 1.81817268886715,
            "bdt": -2.70822286999832,
            "bhd": -2.84777621371774,
            "bmd": -2.8560252038742,
            "bnb": -2.12300512366365,
            "brl": -2.62105113891255,
            "btc": 0.789640289427977,
            "cad": -2.88613517432909,
            "chf": -2.75170720506517,
            "clp": -2.57043970296035,
            "cny": -2.86281478541675,
            "czk": -2.9741796000675,
            "dkk": -3.00787332214788,
            "dot": 0.374904281901901,
            "eos": -1.56200647122554,
            "eth": 0.122659835448126,
            "eur": -3.00396463699978,
            "gbp": -2.70530220102965,
            "gel": -3.0362552127724,
            "hkd": -2.8316915000261,
            "huf": -2.74305377316476,
            "idr": -3.1256813761429,
            "ils": -3.0525251154037,
            "inr": -2.77754756473912,
            "jpy": -2.5634294575236,
            "krw": -3.21026490359575,
            "kwd": -2.8922038004519,
            "lkr": -2.718319030853,
            "ltc": -4.46296436632211,
            "mmk": -2.85602520387404,
            "mxn": -3.90980448922412,
            "myr": -3.31384016588479,
            "ngn": -2.85602520387422,
            "nok": -3.37270076632042,
            "nzd": -1.6195013332677,
            "php": -2.85345960322072,
            "pkr": -2.7572563104105,
            "pln": -3.13241680218187,
            "rub": -4.4640364860663,
            "sar": -2.90242107484245,
            "sek": -2.83099887741268,
            "sgd": -2.80823413580373,
            "thb": -3.02375658569903,
            "try": -2.44185892443742,
            "twd": -2.85828247878339,
            "uah": -2.95094087519511,
            "usd": -2.8560252038742,
            "vef": -2.85602520387415,
            "vnd": -3.1480046737378,
            "xag": -3.61552572025949,
            "xau": -2.5759955140361,
            "xdr": -2.72146868286135,
            "xlm": -0.181744586775227,
            "xrp": -1.18288187034938,
            "yfi": -1.99240848774551,
            "zar": -3.2738145270779,
            "bits": 0.78964028942798,
            "link": -1.11721853100202,
            "sats": 0.789640289427967
          },
          "sparkline": "https://www.coingecko.com/categories/32462934/sparkline.svg"
        }
      }
    ]
  }

  useEffect(() => {
    const fetchTrendingCoins = async () => {
        const response = await getTrendingCoins();
        if (response && Array.isArray(response.coins)) {
            setTrendingCoins(response.coins);
        } else {
            setTrendingCoins([]);
        }
    };
    fetchTrendingCoins();
}, []);

  return (
    <div className="work-proses fix bg-color area-padding-2" style={{
      color: '#fff', 
      // border: '1px solid #f0e000', // Border color and width
      borderRight: '1px solid #f0e000', // Border color and width
      borderLeft: '1px solid #f0e000', // Border color and width
      borderBottom: '1px solid #f0e000', // Border color and width
      borderRadius: '1px',       // Rounded corners
      backgroundColor:'#000033',
      paddingBottom:20,
    }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
              <h3 style={{
                  // fontFamily: "'Brush Script MT', cursive",
                  fontWeight: "bold",
                  color: "#000033",
                  fontSize: "26px",  
                  lineHeight: "1.2", 
              }} > Latest Live Cryptocurrency Trending Coins</h3>
              {/* <p>Latest trending Cryptocurrency coins.</p> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="trending-coin-wrapper">
              <div className="trending-coin-list">
                {trendingCoins.length > 0 && trendingCoins.concat(trendingCoins).map((coin, index) => (
                  <div className="trending-coin-card" key={index}  style={{
                    color: '#fff', 
                    // border: '1px solid #f0e000', // Border color and width
                    borderRight: '1px solid #f0e000', // Border color and width
                    borderLeft: '1px solid #f0e000', // Border color and width
                    // borderBottom: '1px solid #f0e000', // Border color and width
                    borderRadius: '10px',       // Rounded corners
                    backgroundColor:'#000033',
                    paddingBottom:20,
                  }}>
                    <div className="proses-icon point-orange">
                      {/* <span className="rank">{coin.item.market_cap_rank}</span> */}
                      <span className="point-view">{coin.item.market_cap_rank}</span>
                      <Link to="#">
                        <img src={coin.item.small} alt={coin.item.name} className="coin-image" />
                      </Link>
                    </div>
                    <div className="proses-text">
                      <h4>{coin.item.name} ({coin.item.symbol.toUpperCase()})</h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrendingCoin;



// import React, { useEffect, useState } from 'react'
// import { getTrendingCoins } from '../api';
// import { Link } from 'react-router-dom';

// function TrendingCoin() {
//     const [trendingCoin, setTrendingCoin] = useState([])

//     useEffect(()=>{

//         const fetchTrendingCoin = async () =>{
//           const data = await getTrendingCoins();
//           setTrendingCoin(data);
//         };
//         fetchTrendingCoin()
//     },
//     [])
//   return (
//     <div className="work-proses fix bg-color area-padding-2">
//     <div className="container">
//       <div className="row">
//         <div className="col-md-12 col-sm-12 col-xs-12">
//           <div className="section-headline text-center">
//             <h3>Trending Coin </h3>
//             <p>Latest trending Cryptocurrency coins .</p>
//           </div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-md-12 col-sm-12 col-xs-12">
//           <div className="row">
//             <div className="work-proses-inner text-center">
//              {trendingCoin.map((coin)=>{

//                  <div className="col-md-3 col-sm-3 col-xs-12" key={coin.id}>
//                    <div className="single-proses">
//                      <div className="proses-content">
//                        <div className="proses-icon point-orange">
//                          <span className="point-view">02</span>
//                          <Link href="#"><i className="ti-layers" /></Link>
//                        </div>
//                        <div className="proses-text">
//                          <h4>Level 02 instant 20% commission</h4>
//                        </div>
//                      </div>
//                    </div>
//                  </div>
                
//              })

//              }
             
              
//               {/* End column */}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
//   )
// }

// export default TrendingCoin
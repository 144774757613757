import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';
import CryptoCurrencyCarouselArea from './components/CryptoCurrencyCarouselArea';
import axios from 'axios';
import WhatsAppChatButton from './WhatsAppChatButton';

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://server.digitalspayout.com/api/blogs/published');
        if (response && Array.isArray(response.data.data)) {
          setBlogPosts(response.data.data);
        } else {
          setBlogPosts([]);
        }
      } catch (error) {
        console.error('Unable to fetch blogs', error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <>
      <Header />
      <CryptoCurrencyCarouselArea />
      <BlogArea>
        <Container>
          <Row>
            <Col>
              <SectionHeadline>
                <h3>Global Investment Plan News</h3>
                <p>
                  <marquee>
                    Stay updated with the latest trends and insights in the crypto investment world.
                  </marquee>
                </p>
              </SectionHeadline>
            </Col>
          </Row>
          <Row>
            <BlogGrid>
              {blogPosts.map((post) => (
                <BlogCard key={post.id}>
                  <SingleBlog>
                    <ImageContainer>
                      <Link className="image-scale" to={`/blog/${post.id}`}>
                        <Image
                          src={post.imageUrl ? `https://server.digitalspayout.com/${post.imageUrl}` : 'images/bg1.jpg'}
                          alt={post.title}
                          style={{ width: '100%', height: '200px', objectFit: 'cover' }} // Ensures all images are uniform
                        />
                      </Link>
                    </ImageContainer>
                    <BlogContent>
                      <BlogMeta>
                        <span>
                          <i className="fa fa-user" /> {post.author}
                        </span>
                        <span>
                          <i className="fa fa-calendar" /> {post.createdAt}
                        </span>
                        <span>
                          <i className="fa fa-comment-o" /> {post.comments}
                        </span>
                      </BlogMeta>
                      <Link to={`/blog/${post.id}`}>
                        <h4 style={{color:'#fff'}}>{post.title}</h4>
                      </Link>
                      <ReadMoreLink to={`/blog/${post.id}`}>
                        Read More
                      </ReadMoreLink>
                    </BlogContent>
                  </SingleBlog>
                </BlogCard>
              ))}
            </BlogGrid>
          </Row>
        </Container>
      </BlogArea>
      <Footer />
      <WhatsAppChatButton />
    </>
  );
};

// Styled Components
const BlogArea = styled.div`
  background-color: #000033;
  padding: 50px 0;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Col = styled.div`
  width: 100%;
  text-align: center;
`;

const SectionHeadline = styled.div`
  h3 {
    font-weight: bold;
    color: #fff;
    font-size: 46px;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  p {
    color: #fff;
    background-color: #000033;
    border: 2px solid #f0e00f;
    border-radius: 25px;
    padding: 10px;
    margin-top: 10px;
  }
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 30px;
  width: 100%;
`;

const BlogCard = styled.div`
  color: #fff;
  border: 1px solid #f0e000;
  border-radius: 10px;
  background-color: #000033;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SingleBlog = styled.div``;

const ImageContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BlogContent = styled.div`
  margin-top: 15px;
`;

const BlogMeta = styled.div`
  span {
    display: block;
    margin-bottom: 10px;
    i {
      margin-right: 5px;
    }
  }
`;

const ReadMoreLink = styled(Link)`
  color: #f0ef00;
  margin-top: 10px;
`;

export default Blog;


// import React, { useEffect, useState } from 'react'; 
// import { Link } from 'react-router-dom';
// import Header from './components/Header';
// import Footer from './components/Footer';
// import CryptoCurrencyCarouselArea from './components/CryptoCurrencyCarouselArea';
// import axios from 'axios';
// import WhatsAppChatButton from './WhatsAppChatButton';

// const Blog = () => {
//   const [blogPosts, setBlogPosts] = useState([]);

//   useEffect(() => {
//     const fetchBlogs = async () => {
//       try {
//         const response = await axios.get('https://server.digitalspayout.com/api/blogs/published');
//         if (response && Array.isArray(response.data.data)) {
//           setBlogPosts(response.data.data);
//         } else {
//           setBlogPosts([]);
//         }
//       } catch (error) {
//         console.error('Unable to fetch blogs', error);
//       }
//     };

//     fetchBlogs();
//   }, []);

//   return (
//     <>
//       <Header />
//       <CryptoCurrencyCarouselArea />
//       <div className="blog-area fix bg-color area-padding-2">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12 col-sm-12 col-xs-12">
//               <div className="section-headline text-center">
//                 <h3 style={{
//                   fontWeight: "bold",
//                   color: "#000033",
//                   fontSize: "46px",
//                   lineHeight: "1.2",
//                 }}>Global Investment Plan News</h3>
//                 <p style={{ color: '#fff', marginTop: '10px', backgroundColor: '#000033', border: '2px solid #f0e00f', borderRadius: 25 }}>
//                   <marquee>
//                     Stay updated with the latest trends and insights in the crypto investment world.
//                   </marquee>
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="row">
//             <div className="blog-grid home-blog" style={styles.gridContainer}>
//               {blogPosts.map((post) => (
//                 <div key={post.id} className="blog-card" style={styles.blogCard}>
//                   <div className="single-blog">
//                     <div className="blog-image" style={styles.imageContainer}>
//                       <Link className="image-scale" to={`/blog/${post.id}`}>
//                         <img
//                           src={post.imageUrl ? `https://server.digitalspayout.com/${post.imageUrl}` : 'images/bg1.jpg'}
//                           alt={post.title}
//                           style={styles.image}
//                         />
//                       </Link>
//                     </div>
//                     <div className="blog-content" style={styles.content}>
//                       <div className="blog-meta">
//                         <span className="admin-type">
//                           <i className="fa fa-user" /> {post.author}
//                         </span>
//                         <span className="date-type">
//                           <i className="fa fa-calendar" /> {post.createdAt}
//                         </span>
//                         <span className="comments-type">
//                           <i className="fa fa-comment-o" /> {post.comments}
//                         </span>
//                       </div>
//                       <Link to={`/blog/${post.id}`}>
//                         <h4 style={{ color: '#fff' }}>{post.title}</h4>
//                       </Link>
//                       <Link to={`/blog/${post.id}`} className="read-more-btn" style={styles.readMore}>
//                         Read More
//                       </Link>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//       <WhatsAppChatButton />
//     </>
//   );
// };

// const styles = {
//   gridContainer: {
//     display: 'grid',
//     gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', // Automatically adjust columns
//     gap: '20px', // Spacing between cards
//     marginTop: '30px',
//   },
//   blogCard: {
//     color: '#fff',
//     border: '1px solid #f0e000',
//     borderRadius: '10px',
//     backgroundColor: '#000033',
//     padding: '15px',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//   },
//   imageContainer: {
//     width: '100%',
//     height: '200px',
//     overflow: 'hidden',
//   },
//   image: {
//     width: '100%',
//     height: '100%',
//     objectFit: 'cover', // Ensures the image covers the container and maintains aspect ratio
//   },
//   content: {
//     marginTop: '15px',
//   },
//   readMore: {
//     color: '#f0ef00',
//     marginTop: '10px',
//   },
// };

// export default Blog;


// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import Header from './components/Header';
// import Footer from './components/Footer';
// import CryptoCurrencyCarouselArea from './components/CryptoCurrencyCarouselArea';
// import axios from 'axios';
// import WhatsAppChatButton from './WhatsAppChatButton';

// const Blog = () => {
//   const [blogPosts, setBlogPosts] = useState([]);

//   useEffect(() => {
//     const fetchBlogs = async () => {
//       try {
//         const response = await axios.get('https://server.digitalspayout.com/api/blogs/published');
//         if (response && Array.isArray(response.data.data)) {
//           setBlogPosts(response.data.data);
//         } else {
//           setBlogPosts([]);
//         }
//       } catch (error) {
//         console.error('Unable to fetch blogs', error);
//       }
//     };

//     fetchBlogs();
//   }, []);

//   return (
//     <>
//       <Header />
//       <CryptoCurrencyCarouselArea />
//       <div className="blog-area fix bg-color area-padding-2">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12 col-sm-12 col-xs-12">
//               <div className="section-headline text-center">
//                 <h3 style={{
//                   fontWeight: "bold",
//                   color: "#000033",
//                   fontSize: "46px",
//                   lineHeight: "1.2",
//                 }}>Global investment plan news</h3>
//                  <p style={{ color: '#fff', marginTop: '10px', backgroundColor:'#000033', border:'2px solid #f0e00f', borderRadius:25 }}>
//                   <marquee>

//                   Stay updated with the latest trends and insights in the crypto investment world.
//                   </marquee>
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="row">
//             <div className="blog-grid home-blog">
//               {blogPosts.map((post) => (
//                 <div key={post.id} className="col-md-4 col-sm-5 col-xs-12" style={{
//                   color: '#fff',
//                   border: '1px solid #f0e000',
//                   borderRadius: '10px',
//                   backgroundColor: '#000033',
//                   paddingBottom: 20,
//                   padding: 5,
//                   margin:5,
//                   justifyContent: 'space-between',
//                 }}>
//                   <div className="single-blog">
//                     <div className="blog-image">
//                       <Link className="image-scale" to={`/blog/${post.id}`}>
//                         {/* Ensure image URL is valid */}
//                         <img
//                           src={post.imageUrl ? `https://server.digitalspayout.com/${post.imageUrl}` : 'images/bg1.jpg'}
//                           alt={post.title}
//                           style={{ width: '100%', height: '200px', objectFit: 'cover' }} // Ensures all images are uniform
//                         />
//                       </Link>
//                     </div>
//                     <div className="blog-content">
//                       <div className="blog-meta">
//                         <span className="admin-type">
//                           <i className="fa fa-user" /> {post.author}
//                         </span>
//                         <span className="date-type">
//                           <i className="fa fa-calendar" /> {post.createdAt}
//                         </span>
//                         <span className="comments-type">
//                           <i className="fa fa-comment-o" /> {post.comments}
//                         </span>
//                       </div>
//                       <Link to={`/blog/${post.id}`}>
//                         <h4 style={{ color: '#fff' }}>{post.title}</h4>
//                       </Link>
//                       <Link to={`/blog/${post.id}`} className="read-more-btn" style={{ color: '#f0ef00' }}>
//                         Read More
//                       </Link>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//        {/* WhatsApp Chat Button */}
//        <WhatsAppChatButton />
//     </>
//   );
// };

// export default Blog;


import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PaystackPayment = () => {
  const [customerId, setCustomerId] = useState('');
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [reference, setReference] = useState('');
  const [status, setStatus] = useState('');

  
  const [transactionReference, setTransactionReference] = useState('');
  const [transactionDetails, setTransactionDetails] = useState({});

  const handlePayment = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post('https://server.handiwork.com.ng/api/paystack/transaction/initiate', {
        customerId,
        amount,
      });
       console.log('====================================');
       console.log('reponses', response);
       console.log('====================================');
       setReference(response.data.data.reference)
      const { authorization_url, reference } = response.data.data;
      setTransactionReference(response.data.data.reference);
      setAmount('Amount',amount);
      setReference(response.data.data.reference)

    
      window.location.href = authorization_url;
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };
console.log('====================================');
console.log('reference No:', reference);
console.log('====================================');
  useEffect(() => {
    const verifyPayment = async () => {
      if (reference) {
        try {
          const response = await axios.get(`https://server.handiwork.com.ng/api/paystack/transaction/verify/${reference}`);
        //   const { success, data } = response.data;
           console.log('====================================');
           console.log('verify:', response);
           console.log('Amount:', response.data.data.amount);
           console.log('Response Status:', response.status);
           console.log('====================================');
          if ( response.status === 200) {
        //   if (success && data.status === 'success') {
            const verifiedData = {
              status: 'success',
              reference: reference,
              amount:response.data.data.amount,
              customerId: customerId,
            };
        console.log('====================================');
        console.log('details', verifiedData);
        console.log('====================================');
            setTransactionDetails(verifiedData);

            const result= await axios.post('https://server.handiwork.com.ng/api/paystack/transaction/callback', verifiedData);
              console.log('====================================');
              console.log('result', result);
              console.log('====================================');
            setPaymentSuccessful(true);

          } else {
            setError('Payment verification failed. Please contact support.');
          }
        } catch (error) {
          handleError(error);
        }
      }
    };

    verifyPayment();
  }, [reference, customerId]);

  const handleError = (error) => {
    if (error.response) {
      // Server responded with a status other than 200 range
      setError(`Error: ${error.response.data.message || 'Something went wrong. Please try again.'}`);
    } else if (error.request) {
      // Request was made but no response was received
      setError('Network error: No response from the server. Please check your internet connection and try again.');
    } else {
      // Something happened in setting up the request
      setError(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <h2>Paystack Payment</h2>

      <form onSubmit={handlePayment}>
        <div>
          <label>Customer ID</label>
          <input
            type="number"
            value={customerId}
            onChange={(e) => setCustomerId(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Amount (Naira)</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>

        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Make Payment'}
        </button>
      </form>

      {error && <p style={{ color: 'red' }}>{error}</p>}
      {paymentSuccessful && (
        <div>
          <h3>Payment Details</h3>
          <p>Status: {transactionDetails.status}</p>
          <p>Reference: {transactionDetails.reference}</p>
          <p>Amount: {transactionDetails.amount}</p>
          <p>Customer ID: {transactionDetails.customerId}</p>
        </div>
      )}
    </div>
  );
};

export default PaystackPayment;

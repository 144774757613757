import React from 'react';
import styled from 'styled-components';

const phoneNumber = {
  countryCode: '+234',
  number: '9132917335',
  message: 'Hi%2C%20I%20need%20help%20with...',
};

// Ensure correct image path on the server using PUBLIC_URL
const imageUrl = `${process.env.PUBLIC_URL}/images/whatsApp_icon.png`;
const defaultImageUrl = `${process.env.PUBLIC_URL}/images/dlogo.png`; // Update to use PUBLIC_URL

const ChatButtonWrapper = styled.div`
  position: fixed;
  bottom: 25px;
  left: 45px;
  z-index: 1000;
`;

const WhatsAppButton = styled.a`
  display: flex;
  align-items: center;
  padding: 18px;
  background-color: #033;
  border: 2px solid #f0e00f;
  color: white;
  border-radius: 50%;
  text-decoration: none;
  font-size: 11px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.1);
    background-color: #128C7E;
  }
`;

const Tooltip = styled.span`
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  font-size: 11px;
  opacity: 0;
  transition: opacity 0.3s;

  ${WhatsAppButton}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

const WhatsAppIcon = styled.img`
  width: 26px;
  height: 26px;
`;

const WhatsAppText = styled.span`
  display: block;
`;

const WhatsAppChatButton = () => {
  return (
    <ChatButtonWrapper>
      <WhatsAppButton
        href={`https://wa.me/${phoneNumber.countryCode}${phoneNumber.number}?text=${phoneNumber.message}`}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Chat with us on WhatsApp"
      >
        <WhatsAppIcon
          src={imageUrl}
          alt="WhatsApp"
          onError={(e) => e.target.src = defaultImageUrl} // Fallback to default image if main image fails
        />
        <WhatsAppText>Chat with us</WhatsAppText>
        <Tooltip style={{ color: '#f0e00f', backgroundColor: '#000033' }}>
          Chat With Us on WhatsApp
        </Tooltip>
      </WhatsAppButton>
    </ChatButtonWrapper>
  );
};

export default WhatsAppChatButton;



// import React from 'react';
// import styled from 'styled-components';

// const phoneNumber = {
//   countryCode: '+234',
//   number: '9132917335',
//   message: 'Hi%2C%20I%20need%20help%20with...',
// };

// // Ensure correct image path on the server using PUBLIC_URL
// const imageUrl = `${process.env.PUBLIC_URL}/images/whatsApp_icon.png`;
// const defaultImageUrl = "../images/dlogo.png";

// const ChatButtonWrapper = styled.div`
//   position: fixed;
//   bottom: 25px;
//   left: 45px;
//   z-index: 1000;
// `;

// const WhatsAppButton = styled.a`
//   display: flex;
//   align-items: center;
//   padding: 18px;
//   background-color: #033;
//   border: 2px solid #f0e00f;
//   color: white;
//   border-radius: 50%;
//   text-decoration: none;
//   font-size: 11px;
//   font-weight: bold;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   transition: transform 0.3s ease, background-color 0.3s ease;

//   &:hover {
//     transform: scale(1.1);
//     background-color: #128C7E;
//   }
// `;

// const Tooltip = styled.span`
//   visibility: hidden;
//   background-color: #333;
//   color: #fff;
//   text-align: center;
//   border-radius: 5px;
//   padding: 5px;
//   position: absolute;
//   bottom: 70px;
//   left: 50%;
//   transform: translateX(-50%);
//   width: auto;
//   font-size: 11px;
//   opacity: 0;
//   transition: opacity 0.3s;

//   ${WhatsAppButton}:hover & {
//     visibility: visible;
//     opacity: 1;
//   }
// `;

// const WhatsAppIcon = styled.img`
//   width: 26px;
//   height: 26px;
// `;

// const WhatsAppText = styled.span`
//   display: block;
// `;

// const WhatsAppChatButton = () => {
//   return (
//     <ChatButtonWrapper>
//       <WhatsAppButton
//         href={`https://wa.me/${phoneNumber.countryCode}${phoneNumber.number}?text=${phoneNumber.message}`}
//         target="_blank"
//         rel="noopener noreferrer"
//         aria-label="Chat with us on WhatsApp"
//       >
//         <WhatsAppIcon
//           src={imageUrl}
//           alt="WhatsApp"
//         />
//         <WhatsAppText>Chat with us</WhatsAppText>
//         <Tooltip style={{ color: '#f0e00f', backgroundColor: '#000033' }}>
//           Chat With Us on WhatsApp
//         </Tooltip>
//       </WhatsAppButton>
//     </ChatButtonWrapper>
//   );
// };

// export default WhatsAppChatButton;



// import React from 'react';
// import styled from 'styled-components';

// const ChatButtonWrapper = styled.div`
//   position: fixed;
//   bottom: 25px;
//   left: 45px;
//   z-index: 1000;
// `;

// const WhatsAppButton = styled.a`
//   display: flex;
//   align-items: center;
//   padding: 18px;
//   background-color: #033;
//   border: 2px solid #f0e00f;
//   color: white;
//   border-radius: 50%;
//   text-decoration: none;
//   font-size: 11px;
//   font-weight: bold;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   transition: transform 0.3s ease, background-color 0.3s ease;

//   &:hover {
//     transform: scale(1.1);
//     background-color: #128C7E; /* A different WhatsApp green for hover state */
//   }
// `;

// const Tooltip = styled.span`
//   visibility: hidden;
//   background-color: #333;
//   color: #fff;
//   text-align: center;
//   border-radius: 5px;
//   padding: 5px;
//   position: absolute;
//   bottom: 70px;
//   left: 50%; /* Center horizontally */
//   transform: translateX(-50%); /* Offset to center the tooltip */
//   width: auto;
//   font-size: 11px;
//   opacity: 0;
//   transition: opacity 0.3s;

//   ${WhatsAppButton}:hover & {
//     visibility: visible;
//     opacity: 1;
//   }
// `;

// const WhatsAppIcon = styled.img`
//   width: 26px;
//   height: 26px;
// `;

// const WhatsAppText = styled.span`
//   display: block;
// `;

// const WhatsAppChatButton = () => {
//   return (
//     <ChatButtonWrapper>
//       <WhatsAppButton
//         href="https://wa.me/+2349132917335?text=Hi%2C%20I%20need%20help%20with..."
//         target="_blank"
//         rel="noopener noreferrer"
//         aria-label="Chat with us on WhatsApp"
//       >
//         <WhatsAppIcon
//           src="/images/whatsApp_icon.png" // Adjust the path if necessary
//           alt="WhatsApp"
//         />
//         <WhatsAppText>Chat with us</WhatsAppText>
//         <Tooltip style={{ color: '#f0e00f', backgroundColor: '#000033' }}>
//           Chat With Us on WhatsApp
//         </Tooltip>
//       </WhatsAppButton>
//     </ChatButtonWrapper>
//   );
// };

// export default WhatsAppChatButton;



// import React from 'react';
// import styled from 'styled-components';

// const ChatButtonWrapper = styled.div`
//   position: fixed;
//   bottom: 15px;
//   left: 45px;
//   z-index: 1000;
// `;

// const WhatsAppButton = styled.a`
//   display: flex;
//   align-items: center;
//   padding: 5px;
//   background-color: #033;
//   border: 2px solid #f0e00f;
//   color: white;
//   border-radius: 50%;
//   text-decoration: none;
//   font-size: 11px;
//   font-weight: bold;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   transition: transform 0.3s ease, background-color 0.3s ease;

//   &:hover {
//     transform: scale(1.1);
//     background-color: #128C7E; /* A different WhatsApp green for hover state */
//   }

//   &:hover .whatsapp-tooltip {
//     visibility: visible;
//     opacity: 1;
//     margin-left: 58px;
//   }
// `;

// const Tooltip = styled.span`
//   visibility: hidden;
//   background-color: #333;
//   color: #fff;
//   text-align: center;
//   border-radius: 5px;
//   padding: 5px;
//   position: absolute;
//   bottom: 70px;
//   left: -95px; /* Adjusted for left positioning */
//   width: auto;
//   font-size: 11px;
//   opacity: 0;
//   transition: opacity 0.3s;

//   ${WhatsAppButton}:hover & {
//     visibility: visible;
//     opacity: 1;
//   }
// `;

// const WhatsAppIcon = styled.img`
//   width: 26px;
//   height: 26px;
// `;

// const WhatsAppText = styled.span`
//   display: block;
// `;

// const WhatsAppChatButton = () => {
//   return (
//     <ChatButtonWrapper>
//       <WhatsAppButton
//         href="https://wa.me/+2349132917335?text=Hi%2C%20I%20need%20help%20with..."
//         target="_blank"
//         rel="noopener noreferrer"
//         aria-label="Chat with us on WhatsApp"
//       >
//         <WhatsAppIcon
//           src="/images/whatsApp_icon.png" // Adjust the path if necessary
//           alt="WhatsApp"
//         />
//         <WhatsAppText>Chat with us</WhatsAppText>
//         <Tooltip style={{ color: '#f0e00f', backgroundColor: '#000033' }}>
//           Chat With Us on WhatsApp
//         </Tooltip>
//       </WhatsAppButton>
//     </ChatButtonWrapper>
//   );
// };

// export default WhatsAppChatButton;

import React, { useEffect, useRef } from 'react';

const TawkToChat = () => {
  const tawkMessengerRef = useRef();

  useEffect(() => {
    // Initialize the Tawk_API variable outside of the useEffect
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    // Load the Tawk.to script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/6383eee0b0d6371309d16415/1gitlq7hu';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleMinimize = () => {
    if (tawkMessengerRef.current) {
      tawkMessengerRef.current.minimize(); // Minimize the Tawk.to chat widget
    }
  };

  return (
    <div>
      {/* Add a button to minimize the chat widget */}
      <button onClick={handleMinimize} style={minimizeButtonStyle}>
         Chat
      </button>
    </div>
  );
};

// Style for the minimize button (optional)
const minimizeButtonStyle = {
  position: 'fixed',
  bottom: '80px',
  right: '200px',
  left: '0px',
  padding: '10px 20px',
  backgroundColor: '#000033', // Bootstrap primary color
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

export default TawkToChat;


// import React, { useRef } from 'react';
// // import { TawkMessengerReact } from '@tawk.to/tawk-messenger-react';
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

// const TawkToChat = () => {
//   const tawkMessengerRef = useRef();

//   const handleMinimize = () => {
//     if (tawkMessengerRef.current) {
//       tawkMessengerRef.current.minimize(); // Minimize the Tawk.to chat widget
//     }
//   };

//   return (
//     <div>
//       {/* Add a button to minimize the chat widget */}
//       <button onClick={handleMinimize} style={minimizeButtonStyle}>
//           How can we help you?
//       </button>

//       <TawkMessengerReact
//         ref={tawkMessengerRef} // Set the ref to the TawkMessengerReact component
//         propertyId="dc0984b4a1ffca79f76a3ff972eed8045899861e" // Replace with your Tawk.to property ID
//         widgetId="1gitlq7hu"     // Optionally, replace with your Tawk.to widget ID
//         placeholder="How can we help you?"   // Customize the placeholder text
//         style={{
//           position: 'fixed',  // You can adjust the position as needed
//           bottom: '20px',
//           right: '20px',
//           zIndex: 9999,      // Ensure it appears above other content
//         }}
//       />
//     </div>
//   );
// };

// // Style for the minimize button (optional)
// const minimizeButtonStyle = {
//   position: 'fixed',
//   bottom: '80px',
//   right: '20px',
//   padding: '10px 20px',
//   backgroundColor: '#007bff', // Bootstrap primary color
//   color: 'white',
//   border: 'none',
//   borderRadius: '5px',
//   cursor: 'pointer',
// };

// export default TawkToChat;


// import React from 'react';
// // import TawkMessengerReact  from 'tawk-messenger-react';
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

// const TawkToChat = () => {
//   return (
//     <TawkMessengerReact
//      propertyId="dc0984b4a1ffca79f76a3ff972eed8045899861e"
//     widgetId="1gitlq7hu"
    
//       placeholder="How can we help you?"   // Customize the placeholder text
//       style={{
//         position: 'fixed',  // You can adjust the position as needed
//         bottom: '20px',
//         right: '20px',
//         zIndex: 9999,      // Ensure it appears above other content
//       }}
//     />
    
//   );
// };

// export default TawkToChat;

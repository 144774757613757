import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PaymentHistoryArea = () => {
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDeposits = async () => {
      try {
        const response = await axios.get('https://server.digitalspayout.com/api/postDeposits/recent-deposits');
        setDeposits(response.data.data);
      } catch (error) {
        console.error('Error fetching deposits:', error);
      }
    };

    const fetchWithdrawals = async () => {
      try {
        const response = await axios.get('https://server.digitalspayout.com/api/postDeposits/recent-withdrawals');
        setWithdrawals(response.data.data);
      } catch (error) {
        console.error('Error fetching withdrawals:', error);
      }
    };

    fetchDeposits();
    fetchWithdrawals();
    setLoading(false);
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="payment-history-area fix area-padding-2" style={{
      color: '#fff',
      borderRight: '1px solid #f0e000',
      borderLeft: '1px solid #f0e000',
      borderBottom: '1px solid #f0e000',
      borderRadius: '10px',
      backgroundColor: '#000033',
      paddingBottom: 20,
      background: 'url(img/blog/b1.jpg)',
      backgroundRepeat:' no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
    }}>
      <div className="container" style={{
        color: '#fff',
        borderRight: '1px solid #f0e000',
        borderLeft: '1px solid #f0e000',
        borderBottom: '1px solid #f0e000',
        borderRadius: '10px',
        backgroundColor: '#000033',
        paddingBottom: 20,
      }}>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="section-headline text-center">
              <h3 style={{ color: "#fff", fontSize: "26px", lineHeight: "1.2" }}>
                Deposits and Withdrawals: Evidence of Investor Success
              </h3>
              <p style={{ color: "#fff", lineHeight: "1.2" }}>
                At Digital PayOut, we are proud to showcase the recent transactions made by our valued investors. 
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="deposite-content">
              <div className="diposite-box" style={{ borderRight: '1px solid #f0e000', borderLeft: '1px solid #f0e000', borderRadius: '10px', backgroundColor: '#000033', paddingBottom: 20 }}>
                <h4>Recent Deposits</h4>
                <span><i className="flaticon-005-savings"></i></span>
                <div className="deposite-table">
                  <table className="table table-striped table-hover table-sm">
                    <caption style={{ color: '#fff', textAlign: 'center' }}>Recent deposit transactions made by our investors.</caption>
                    <thead style={{ backgroundColor: "#000033", color: "#fff" }}>
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Currency</th>
                      </tr>
                    </thead>
                    <tbody>
                      {deposits.map(deposit => (
                        <tr key={deposit.id} style={{ border: '2px solid #f0e000', backgroundColor: '#000033' }}>
                          <td>${deposit.investorName}</td>
                          <td>{formatDate(deposit.createdAt)}</td>
                          <td>{deposit.amount}</td>
                          <td>{deposit.currency}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="deposite-content" style={{ borderRadius: '10px', backgroundColor: '#000033', paddingBottom: 20 }}>
              <div className="diposite-box" style={{ borderRight: '1px solid #f0e000', borderLeft: '1px solid #f0e000', borderRadius: '10px', backgroundColor: '#000033', paddingBottom: 20 }}>
                <h4>Recent Withdrawals</h4>
                <span><i className="flaticon-042-wallet"></i></span>
                <div className="deposite-table">
                  <table className="table table-striped table-hover table-sm">
                    <caption style={{ color: '#fff', textAlign: 'center' }}>Recent withdrawal transactions made by our investors.</caption>
                    <thead style={{ backgroundColor: "#000033", color: "#fff" }}>
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Currency</th>
                      </tr>
                    </thead>
                    <tbody>
                      {withdrawals.map(withdrawal => (
                        <tr key={withdrawal.id} style={{ border: '2px solid #f0e000', backgroundColor: '#000033' }}>
                          <td>${withdrawal.investorName}</td>
                          <td>{formatDate(withdrawal.createdAt)}</td>
                          <td>{withdrawal.amount}</td>
                          <td>{withdrawal.currency}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistoryArea;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const PaymentHistoryArea = () => {
//   const [deposits, setDeposits] = useState([]);
//   const [withdrawals, setWithdrawals] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchDeposits = async () => {
//       try {
//         const response = await axios.get('https://server.digitalspayout.com/api/postDeposits/recent-deposits');
//         setDeposits(response.data.data);
//       } catch (error) {
//         console.error('Error fetching deposits:', error);
//       }
//     };

//     const fetchWithdrawals = async () => {
//       try {
//         const response = await axios.get('https://server.digitalspayout.com/api/postDeposits/recent-withdrawals');
//         setWithdrawals(response.data.data);
//       } catch (error) {
//         console.error('Error fetching withdrawals:', error);
//       }
//     };

//     fetchDeposits();
//     fetchWithdrawals();
//     setLoading(false);
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="payment-history-area  fix area-padding-2" style={{
//       color: '#fff',
//       borderRight: '1px solid #f0e000',
//       borderLeft: '1px solid #f0e000',
//       borderBottom: '1px solid #f0e000',
//       borderRadius: '10px',
//       backgroundColor: '#000033',
//       paddingBottom: 20,
//       background: 'url(img/blog/b1.jpg)',
//       backgroundRepeat:' no-repeat',
//       backgroundPosition: 'top center',
//       backgroundSize: 'cover',
//     }}>
//       <div className="container" style={{
//         color: '#fff',
//         borderRight: '1px solid #f0e000',
//         borderLeft: '1px solid #f0e000',
//         borderBottom: '1px solid #f0e000',
//         borderRadius: '10px',
//         backgroundColor: '#000033',
//         paddingBottom: 20,
//       }}>
//         <div className="row">
//           <div className="col-md-12 col-sm-12 col-xs-12">
//             <div className="section-headline text-center">
//               <h3 style={{ color: "#fff", fontSize: "26px", lineHeight: "1.2" }}>
//                 Deposits and Withdrawals: Evidence of Investor Success
//               </h3>
//               <p style={{ color: "#fff", lineHeight: "1.2" }}>
//                 At Digital PayOut, we are proud to showcase the recent transactions made by our valued investors. 
//               </p>
//             </div>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-md-12 col-sm-12 col-xs-12">
//             <div className="deposite-content">
//               <div className="diposite-box" style={{ borderRight: '1px solid #f0e000', borderLeft: '1px solid #f0e000', borderRadius: '10px', backgroundColor: '#000033', paddingBottom: 20 }}>
//                 <h4>Recent Deposits</h4>
//                 <span><i className="flaticon-005-savings"></i></span>
//                 <div className="deposite-table">
//                   <table className="table table-striped table-hover table-sm">
//                     <caption style={{ color: '#fff', textAlign: 'center' }}>Recent deposit transactions made by our investors.</caption>
//                     <thead style={{ backgroundColor: "#000033", color: "#fff" }}>
//                       <tr>
//                         <th>Name</th>
//                         <th>Date</th>
//                         <th>Amount</th>
//                         <th>Currency</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {deposits.map(deposit => (
//                         <tr key={deposit.id} style={{ border: '2px solid #f0e000', backgroundColor: '#000033' }}>
//                           <td>${deposit.investorName}</td>
//                           <td>{deposit.createdAt}</td>
//                           <td>{deposit.amount}</td>
//                           <td>{deposit.currency}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>

//             <div className="deposite-content" style={{ borderRadius: '10px', backgroundColor: '#000033', paddingBottom: 20 }}>
//               <div className="diposite-box" style={{ borderRight: '1px solid #f0e000', borderLeft: '1px solid #f0e000', borderRadius: '10px', backgroundColor: '#000033', paddingBottom: 20 }}>
//                 <h4>Recent Withdrawals</h4>
//                 <span><i className="flaticon-042-wallet"></i></span>
//                 <div className="deposite-table">
//                   <table className="table table-striped table-hover table-sm">
//                     <caption style={{ color: '#fff', textAlign: 'center' }}>Recent withdrawal transactions made by our investors.</caption>
//                     <thead style={{ backgroundColor: "#000033", color: "#fff" }}>
//                       <tr>
//                         <th>Name</th>
//                         <th>Date</th>
//                         <th>Amount</th>
//                         <th>Currency</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {withdrawals.map(withdrawal => (
//                         <tr key={withdrawal.id} style={{ border: '2px solid #f0e000', backgroundColor: '#000033' }}>
//                           <td>${withdrawal.investorName}</td>
//                           <td>{withdrawal.createdAt}</td>
//                           <td>{withdrawal.amount}</td>
//                           <td>{withdrawal.currency}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PaymentHistoryArea;


// import React from 'react';

// const PaymentHistoryArea = () => {
//   const deposits = [
//     { id: 1, name: 'Admond Sayhel', date: 'Jan 02, 2020', amount: '$1000', currency: 'Bitcoin' },
//     { id: 2, name: 'Jonshon', date: 'Dec 12, 2019', amount: '$5000', currency: 'USD' },
//     { id: 3, name: 'Hopper', date: 'Dec 22, 2019', amount: '$4000', currency: 'Ripple' },
//     { id: 4, name: 'Admond Sayhel', date: 'Jan 02, 2020', amount: '$3000', currency: 'Bitcoin' },
//     { id: 5, name: 'Anjel July', date: 'Jan 05, 2020', amount: '$500', currency: 'USD' },
//     { id: 6, name: 'Lagisha', date: 'Jan 12, 2020', amount: '$5000', currency: 'Bitcoin' },
//   ];

//   const withdrawals = [
//     { id: 1, name: 'Arnold', date: 'Jan 04, 2020', amount: '$1000', currency: 'USD' },
//     { id: 2, name: 'Jhon Abra', date: 'Jan 07, 2020', amount: '$6000', currency: 'USD' },
//     { id: 3, name: 'Lanisha', date: 'Jan 13, 2020', amount: '$5000', currency: 'USD' },
//     { id: 4, name: 'Gongales', date: 'Jan 12, 2020', amount: '$2000', currency: 'USD' },
//     { id: 5, name: 'Admond Sayhel', date: 'Jan 10, 2020', amount: '$1000', currency: 'USD' },
//     { id: 6, name: 'Remond', date: 'Jan 02, 2020', amount: '$3000', currency: 'USD' },
//   ];

//   return (
//     <div className="payment-history-area  fix area-padding-2"  style={{
//       color: '#fff', 
//       // border: '1px solid #f0e000', // Border color and width
//       borderRight: '1px solid #f0e000', // Border color and width
//       borderLeft: '1px solid #f0e000', // Border color and width
//       borderBottom: '1px solid #f0e000', // Border color and width
//       borderRadius: '10px',       // Rounded corners
//       backgroundColor:'#000033',
//       paddingBottom:20,
//     }}>
//       <div className="container" style={{
//                         color: '#fff', 
//                         // border: '1px solid #f0e000', // Border color and width
//                         borderRight: '1px solid #f0e000', // Border color and width
//                         borderLeft: '1px solid #f0e000', // Border color and width
//                         borderBottom: '1px solid #f0e000', // Border color and width
//                         borderRadius: '10px',       // Rounded corners
//                         backgroundColor:'#000033',
//                         paddingBottom:20,
//                       }} >
//         <div className="row">
//           <div className="col-md-12 col-sm-12 col-xs-12">
//             <div className="section-headline text-center">
//               <h3 style={{
//                 // fontWeight: "bold",
//                 color: "#fff",
//                 fontSize: "26px", 
//                 lineHeight: "1.2",
//               }}>Deposits and Withdrawals: Evidence of Investor Success</h3>
//               <p style={{
//                 // fontWeight: "bold",
//                 color: "#fff",
//                 // fontSize: "16px", 
//                 lineHeight: "1.2",
//                 // fontFamily: "'Brush Script MT', cursive",
//               }}>
//                 At Digital PayOut, we are proud to showcase the recent transactions made by our valued investors. These deposits and withdrawals stand as tangible evidence of the trust and success our community experiences on our platform. Each entry reflects a real investor engaging with the world of cryptocurrency, realizing returns on their investments, and securing profits.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-12 col-sm-12 col-xs-12">
//             <div className="deposite-content">
//               <div className="diposite-box"  style={{
//                         color: '#fff', 
//                         // border: '1px solid #f0e000', // Border color and width
//                         borderRight: '1px solid #f0e000', // Border color and width
//                         borderLeft: '1px solid #f0e000', // Border color and width
//                         // borderBottom: '1px solid #f0e000', // Border color and width
//                         borderRadius: '10px',       // Rounded corners
//                         backgroundColor:'#000033',
//                         paddingBottom:20,
//                       }}>
//                 <h4>Recent Deposits</h4>
//                 <span><i className="flaticon-005-savings"></i></span>
//                 <div className="deposite-table"  style={{
//                         color: '#fff', 
//                         // border: '1px solid #f0e000', // Border color and width
//                         borderRight: '1px solid #f0e000', // Border color and width
//                         borderLeft: '1px solid #f0e000', // Border color and width
//                         // borderBottom: '1px solid #f0e000', // Border color and width
//                         borderRadius: '10px',       // Rounded corners
//                         backgroundColor:'#000033',
//                         paddingBottom:20,
//                       }}>
//                   <table  className="table table-striped  table-hover table-sm">
//                   <caption style={{color:'#fff', textAlign:'center'}}>we are proud to showcase the recent transactions made by our valued investors.</caption>
//                     <thead style={{ backgroundColor: "#000033", color: "#fff" }}>
//                       <tr  style={{
//                         color: '#fff', 
//                         border: '3px solid #f0e000', // Border color and width
                        
//                         borderRadius: '20px',       // Rounded corners
//                         backgroundColor:'#000033',
//                         paddingBottom:20,
//                       }}>
//                         <th>Name</th>
//                         <th>Date</th>
//                         <th>Amount</th>
//                         <th>Currency</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {deposits.map(deposit => (
//                         <tr key={deposit.id}  style={{
//                           color: '#fff', 
//                           border: '2px solid #f0e000', // Border color and width
//                           borderRight: '1px solid #f0e000', // Border color and width
//                           borderLeft: '1px solid #f0e000', // Border color and width
//                           borderBottom: '5px solid #f0e00c', // Border color and width
//                           borderRadius: '10px',       // Rounded corners
//                           backgroundColor:'#000033',
//                           paddingBottom:20,
//                         }}>
//                           <td>{deposit.name}</td>
//                           <td>{deposit.date}</td>
//                           <td>{deposit.amount}</td>
//                           <td>{deposit.currency}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//             <div className="deposite-content"  style={{
//                         color: '#fff', 
//                         // border: '1px solid #f0e000', // Border color and width
//                         // borderRight: '1px solid #f0e000', // Border color and width
//                         // borderLeft: '1px solid #f0e000', // Border color and width
//                         // borderBottom: '1px solid #f0e000', // Border color and width
//                         borderRadius: '10px',       // Rounded corners
//                         backgroundColor:'#000033',
//                         paddingBottom:20,
//                       }}>
//               <div className="diposite-box"  style={{
//                         color: '#fff', 
//                         // border: '1px solid #f0e000', // Border color and width
//                         borderRight: '1px solid #f0e000', // Border color and width
//                         borderLeft: '1px solid #f0e000', // Border color and width
//                         // borderBottom: '1px solid #f0e000', // Border color and width
//                         borderRadius: '10px',       // Rounded corners
//                         backgroundColor:'#000033',
//                         paddingBottom:20,
//                       }}>
//                 <h4>Recent Withdrawals</h4>
//                 <span><i className="flaticon-042-wallet"></i></span>
//                 <div className="deposite-table"  style={{
//                         color: '#fff', 
//                         // border: '1px solid #f0e000', // Border color and width
//                         borderRight: '1px solid #f0e000', // Border color and width
//                         borderLeft: '1px solid #f0e000', // Border color and width
//                         // borderBottom: '1px solid #f0e000', // Border color and width
//                         borderRadius: '10px',       // Rounded corners
//                         backgroundColor:'#000033',
//                         paddingBottom:20,
//                       }}>
//                 <table  className="table table-striped  table-hover table-sm">
//                 <caption style={{color:'#fff', textAlign:'center'}}>we are proud to showcase the recent transactions made by our valued investors.</caption>
//                     <thead style={{ backgroundColor: "#000033", color: "#fff" }}>
//                       <tr  style={{
//                         color: '#fff', 
//                         border: '2px solid #f0e000', // Border color and width
//                         borderRight: '1px solid #f0e000', // Border color and width
//                         borderLeft: '1px solid #f0e000', // Border color and width
//                         borderBottom: '5px solid #f0e00c', // Border color and width
//                         borderRadius: '10px',       // Rounded corners
//                         backgroundColor:'#000033',
//                         paddingBottom:20,
//                       }}>
//                         <th>Name</th>
//                         <th>Date</th>
//                         <th>Amount</th>
//                         <th>Currency</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {withdrawals.map(withdrawal => (
//                         <tr key={withdrawal.id}  style={{
//                           color: '#fff', 
//                           border: '2px solid #f0e000', // Border color and width
//                           borderRight: '1px solid #f0e000', // Border color and width
//                           borderLeft: '1px solid #f0e000', // Border color and width
//                           borderBottom: '1px solid #f0e000', // Border color and width
//                           borderRadius: '10px',       // Rounded corners
//                           backgroundColor:'#000033',
//                           paddingBottom:20,
//                         }}>
//                           <td>{withdrawal.name}</td>
//                           <td>{withdrawal.date}</td>
//                           <td>{withdrawal.amount}</td>
//                           <td>{withdrawal.currency}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PaymentHistoryArea;


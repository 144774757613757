import React from 'react';
import './investmentArea.css'; // Assuming you have a CSS file for additional styling
import { Link } from 'react-router-dom';

const InvestArea = () => {
  const plans = [
    {
      id: 1,
      rate: '5%',
      duration: '10 days',
      minInvest: '$100',
      maxInvest: '$1000',
      avgMonthly: '50%',
      label: 'gold',
      image: './images/cryto1.jpeg', // Add your image path here
    },
    {
      id: 2,
      rate: '15%',
      duration: '30 days',
      minInvest: '$1000',
      maxInvest: '$10000',
      avgMonthly: '100%',
      label: 'silver',
      image: './images/cryto2.jpeg', // Add your image path here
    },
    {
      id: 3,
      rate: '50%',
      duration: '45 days',
      minInvest: '$1000',
      maxInvest: '$50000',
      avgMonthly: '200%',
      label: 'Popular',
      image: './images/cryto1.jpeg', // Add your image path here
    },
    {
      id: 4,
      rate: '100%',
      duration: '60 days',
      minInvest: '$1000',
      maxInvest: '$50000',
      avgMonthly: '250%',
      label: 'Bronze',
      image: './images/cryto5.jpeg', // Add your image path here
    },
    {
      id: 5,
      rate: '200%',
      duration: '60 days',
      minInvest: '$3000',
      maxInvest: '$250000',
      avgMonthly: '200%',
      label: 'Silver',
      image: './images/cryto6.jpeg', // Add your image path here
    },
    {
      id: 6,
      rate: '300%',
      duration: '60 days',
      minInvest: '$5000',
      maxInvest: '$5000000',
      avgMonthly: '250%',
      label: 'Uranium',
      image: './images/cryto6.jpeg', // Add your image path here
    },
    {
      id: 7,
      rate: '400%',
      duration: '90 days',
      minInvest: '$5000',
      maxInvest: '$5000000',
      avgMonthly: '400%',
      label: 'Gold',
      image: './images/cryto6.jpeg', // Add your image path here
    },
    {
      id: 8,
      rate: '500%',
      duration: '100 days',
      minInvest: '$25000',
      maxInvest: '$25000000',
      avgMonthly: '500%',
      label: 'Platinium',
      image: './images/cryto6.jpeg', // Add your image path here
    },
  ];

  return (

    <>
    <div className="invest-area bg-color area-padding-2" style={{
      color: '#fff', 
      // border: '1px solid #f0e000', // Border color and width
      borderRight: '1px solid #f0e000', // Border color and width
      borderLeft: '1px solid #f0e000', // Border color and width
      // borderBottom: '1px solid #f0e000', // Border color and width
      borderRadius: '1px',       // Rounded corners
      backgroundColor:'#000033',
      paddingBottom:20,
    }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12" style={{
                        color: '#fff', 
                        // border: '1px solid #f0e000', // Border color and width
                        borderRight: '1px solid #f0e000', // Border color and width
                        borderLeft: '1px solid #f0e000', // Border color and width
                        // borderBottom: '1px solid #f0e000', // Border color and width
                        borderRadius: '10px',       // Rounded corners
                        backgroundColor:'#000033',
                        paddingBottom:20,
                      }}>
            {/* <div className="section-headline text-center">
              <h3>The best investment plan</h3>
              <p>
                Help agencies to define their new business objectives and then create professional software.
              </p>
            </div> */}
             <div className="section-headline text-center">
          <h3 style={{
        // fontFamily: "'Brush Script MT', cursive",
        // fontWeight: "bold",
        color: "#fff",
        fontSize: "26px",  
        lineHeight: "1.2",  
    }}>
              INVESTMENT OFFER
          </h3>
          <h5 style={{color:'#fff'}}>
              Our Investment Best Plans
          </h5>
          <p style={{
        // fontFamily: "'Brush Script MT', cursive",
        fontWeight: "bold",
        color: "#fff",
        fontSize: "16px",  // Adjust the size as needed
        // lineHeight: "1.2", 
        paddingBottom:'25',
        marginBottom:'25',
        margin:'25',
    }} >
              Our experts have set up these investment plans strategically, which minizes your risk
              of investment and maximizes the return of interest
          </p>

          </div>
          </div>
        </div>
        <div className="row pricing-content">
          {plans.map(plan => (
            <div className="col-md-3 col-sm-6 col-xs-12" key={plan.id} >
               <div className="card-image-wrapper">
                  <img src={plan.image} alt={plan.label} className="card-image" />
                </div>
              <div className="pri_table_list" style={{
                        // border: '2px solid #000033', // Border color and width
                        border: '5px solid #f0e000', // Border color and width
                        borderRadius: '10px',       // Rounded corners
                        backgroundColor:'#ffffff'
                      }}>
                {plan.label && <span className="base">{plan.label}</span>}
                {/* <div className="card-image-wrapper">
                  <img src={plan.image} alt={plan.label} className="card-image" />
                </div> */}
                <div className="top-price-inner">
                  <div className="rates">
                    <span className="prices">{plan.rate}</span><span className="users">Daily</span>
                  </div>
                  <span className="per-day">{plan.duration}</span>
                </div>
                <ol className="pricing-text" style={{backgroundColor:'#f0f0ff', color:'#000033', textAlign:'center', borderRadius:1, padding:1,}}>
                  <li className="check">Minimum Invest : {plan.minInvest}</li>
                  <li className="check">Maximum Invest : {plan.maxInvest}</li>
                  <li className="check">Average Monthly : {plan.avgMonthly}</li>
                </ol>
                <div className="price-btn blue">
                  <Link className="blue" to="https://dashboard.digitalspayout.com/signup">Deposit</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    
    </>
  );
};

export default InvestArea;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios'; // Axios for API calls
// import './investmentArea.css'; // Assuming you have a CSS file for additional styling
// import { Link } from 'react-router-dom';

// const InvestArea = () => {
//   const [plans, setPlans] = useState([]); // State to hold fetched plans

//   // Fetch data from API
//   useEffect(() => {
//     const fetchPlans = async () => {
//       try {
//         const response = await axios.get('http://localhost:3005/api/subscription/allPlans');
//         const fetchedPlans = response.data.data;

//         // Save the latest six plans to state
//         setPlans(fetchedPlans.slice(0, 6)); 
//       } catch (error) {
//         console.error('Error fetching the plans:', error);
//       }
//     };

//     fetchPlans(); // Initial fetch when the component mounts

//     // Optional: Setup a polling mechanism to refetch data periodically (e.g., every minute)
//     const intervalId = setInterval(fetchPlans, 60000); // Refetch every 60 seconds

//     return () => clearInterval(intervalId); // Clean up interval on unmount
//   }, []); // Empty dependency array means this runs once on mount

//   return (
//     <>
//       <div className="invest-area bg-color area-padding-2" style={{
//         color: '#fff',
//         borderRight: '1px solid #f0e000',
//         borderLeft: '1px solid #f0e000',
//         borderRadius: '1px',
//         backgroundColor: '#000033',
//         paddingBottom: 20,
//       }}>
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12 col-sm-12 col-xs-12" style={{
//               color: '#fff',
//               borderRight: '1px solid #f0e000',
//               borderLeft: '1px solid #f0e000',
//               borderRadius: '10px',
//               backgroundColor: '#000033',
//               paddingBottom: 20,
//             }}>
//               <div className="section-headline text-center">
//                 <h3 style={{ color: "#fff", fontSize: "26px", lineHeight: "1.2" }}>
//                   INVESTMENT OFFER
//                 </h3>
//                 <h5 style={{ color: '#fff' }}>
//                   Our Investment Best Plans
//                 </h5>
//                 <p style={{ fontWeight: "bold", color: "#fff", fontSize: "16px", paddingBottom: '25', marginBottom: '25', margin: '25' }}>
//                   Our experts have set up these investment plans strategically, which minimizes your risk of investment and maximizes the return of interest
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="row pricing-content">
//             {plans.map(plan => (
//               <div className="col-md-3 col-sm-6 col-xs-12" key={plan.id}>
//                 <div className="card-image-wrapper">
//                   <img src={plan.image} alt={plan.label} className="card-image" />
//                 </div>
//                 <div className="pri_table_list" style={{
//                   border: '5px solid #f0e000',
//                   borderRadius: '10px',
//                   backgroundColor: '#ffffff'
//                 }}>
//                   {plan.label && <span className="base">{plan.label}</span>}
//                   <div className="top-price-inner">
//                     <div className="rates">
//                       <span className="prices">{plan.rate}</span><span className="users">Daily</span>
//                     </div>
//                     <span className="per-day">{plan.duration}</span>
//                   </div>
//                   <ol className="pricing-text" style={{ backgroundColor: '#f0f0ff', color: '#000033', textAlign: 'center', borderRadius: 1, padding: 1 }}>
//                     <li className="check">Minimum Invest : ${plan.minInvest}</li>
//                     <li className="check">Maximum Invest : ${plan.maxInvest}</li>
//                     <li className="check">Average Monthly : {plan.avgMonthly}</li>
//                   </ol>
//                   <div className="price-btn blue">
//                     <Link className="blue" to="/register">Deposit</Link>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default InvestArea;

import React, { useState } from 'react';
import './DigitalTrade.css'; // Import your custom CSS for styling

// WalletInfo Component
const WalletInfo = ({ walletData }) => {
  return (
    <div className="wallet-info">
      <h2>Wallet Balance: {walletData.balance}</h2>
      <p>{walletData.rank} | Profit per hour: {walletData.profitPerHour}</p>
      <h2>{walletData.userName}</h2>
      <p>Email: {walletData.email}</p>
    </div>
  );
};

// CardItem Component
const CardItem = ({ card }) => {
  return (
    <div className="card">
      <h3>{card.title}</h3>
      <p>{card.description}</p>
      <p>Amount: {card.amount}</p>
      <p>Period: {card.period}</p>
      <button className="deposit-btn">Deposit</button>
    </div>
  );
};

// CardList Component
const CardList = ({ cardData }) => {
  return (
    <div className="cards-section">
      {cardData.map((card, index) => (
        <CardItem key={index} card={card} />
      ))}
    </div>
  );
};

// BottomNav Component
const BottomNav = ({ navItems, activeSection, setActiveSection }) => {
  return (
    <div className="bottom-nav">
      {navItems.map((item, index) => (
        <div
          key={index}
          className={`nav-item ${activeSection === item ? 'active' : ''}`}
          onClick={() => setActiveSection(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

// SettingsIcon Component
const SettingsIcon = () => {
  return <div className="settings-icon">⚙️</div>;
};


const HomeSectionContent = ({ walletData, cardData }) => {
    return (
      <div className="home-section">
        {/* Wallet Section */}
        <div className="wallet-section">
          <WalletInfo walletData={walletData} />
          <SettingsIcon />
        </div>
  
        {/* Cards Section with Deposit Buttons */}
        <div className="cards-section-container">
          <CardList cardData={cardData} />
        </div>
      </div>
    );
  };
  

const WalletContent = ({ walletData,  investments  }) => {
    return (
      <div className="home-section">
        {/* Wallet Section */}
        <div className="wallet-section">
          <WalletSectionContent walletData={walletData} />
          <SettingsIcon />
        </div>
  
        {/* Cards Section with Deposit Buttons */}
        <div className="cards-section-container">
        <InvestmentHistorySection investments={investments} />
        </div>
      </div>
    );
  };
  

const InvestmentHistorySection = ({ investments }) => {
  return (
    <div className="investment-history">
      <h2>Investment History</h2>
      <div className="investment-list">
        {investments.map((investment, index) => (
          <div key={index} className="investment-item">
            <div className="investment-details">
              <p>Amount: {investment.amount}</p>
              <p>Status: {investment.status}</p>
              <p>Profit: {investment.profit}</p>
              <p>Date: {investment.date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};



const WalletSectionContent = ({ walletData }) => {
  return (
    <div className="wallet-section">
      <div className="wallet-info">
        <h2>Wallet Balance: {walletData.balance}</h2>
        <p>{walletData.rank} | Profit per hour: {walletData.profitPerHour}</p>
      </div>
      <div className="user-info">
        <h3>{walletData.userName}</h3>
        <p>Email: {walletData.email}</p>
      </div>
    </div>
  );
};



// ProfileSection Component
// const ProfileSection = () => {
//   return <div className="section-content">This is your Profile</div>;
// };


const ProfileSection = ({ profileData }) => {
  return (
    <div className="profile-card">
      <div className="profile-header">
        <img
          src={profileData.avatarUrl || './images/default-avatar.png'}
          alt="User Avatar"
          className="profile-avatar"
        />
        <h2>{profileData.name}</h2>
        <p className="profile-email">{profileData.email}</p>
      </div>
      
      <div className="profile-details">
        <div className="detail-item">
          <h3>Subscription Plan</h3>
          <p>{profileData.plan}</p>
        </div>
        <div className="detail-item">
          <h3>Join Date</h3>
          <p>{profileData.joinDate}</p>
        </div>
        <div className="detail-item">
          <h3>Account Status</h3>
          <p>{profileData.status}</p>
        </div>
      </div>
    </div>
  );
};



// Main DigitalTrade Component
const DigitalTrade = () => {
  // Dummy data objects
  const walletData = {
    balance: '22,749,729 USDT',
    rank: 'Legendary (CEO)',
    profitPerHour: '+126.42K',
    userName: 'Pheeky Moore',
    email: 'pheeky@gmail.com',
  };

  const cardData = [
    {
      title: 'Daily Reward',
      description: 'Claim your daily 10% reward in 09:55',
      amount: '$400',
      period: '30 Days',
    },
    {
      title: 'Daily Reward',
      description: 'Claim your daily 10% reward in 09:55',
      amount: '$400',
      period: '30 Days',
    },
    {
      title: 'Daily Reward',
      description: 'Claim your daily 10% reward in 09:55',
      amount: '$400',
      period: '30 Days',
    },
  ];

  const navItems = ['Home', 'Investment History', 'Wallet', 'Profile'];

  // State to manage which section is active
  const [activeSection, setActiveSection] = useState('Home');

  const investments = [
    { amount: '$1000', status: 'Completed', profit: '$120', date: '2024-08-20' },
    { amount: '$2000', status: 'In Progress', profit: '$250', date: '2024-08-21' },
    { amount: '$1500', status: 'Failed', profit: '$0', date: '2024-08-22' },
  ];  


  const profileData = {
    avatarUrl: './images/user-avatar.jpg',
    name: 'Pheeky Moore',
    email: 'pheeky@gmail.com',
    plan: 'Legendary (CEO)',
    joinDate: '2024-01-15',
    status: 'Active',
  };
  
  // Render the correct section based on the active navigation item
  const renderActiveSection = () => {
    switch (activeSection) {
      case 'Home':
        return <HomeSectionContent walletData={walletData} cardData={cardData} />;
        // return <HomeSection />;
      case 'Investment History':
        return <InvestmentHistorySection investments={investments} />
        case 'Wallet':
          return <WalletContent walletData={walletData} investments={investments} />
          // return <WalletSectionContent walletData={walletData} />

      case 'Profile':
        return <ProfileSection profileData={profileData} />;
      default:
        return  <HomeSectionContent walletData={walletData} cardData={cardData} />
        // return <HomeSection />;
    }
  };

  return (
    <div className="digital-trade">
      {/* Top Section: Wallet Balance & Details */}
      {/* <div className="wallet-section">
        <WalletInfo walletData={walletData} />
        <SettingsIcon />
      </div> */}

      {/* Cards Section with Deposit Buttons */}
      <div className="cards-section-container">
        {/* <CardList cardData={cardData} /> */}


        {/* Render the active section */}
        <div className="main-section">
          {renderActiveSection()}
        </div>
        {/* Center Section: Mascot Image */}
        <div className="mascot-section">
          <img src="./images/dlogo.png" alt="Hamster Mascot" className="mascot-img" />
        </div>

        {/* Bottom Navigation */}
        <BottomNav navItems={navItems} activeSection={activeSection} setActiveSection={setActiveSection} />
      </div>
    </div>
  );
};

export default DigitalTrade;


// import React from 'react';
// import './DigitalTrade.css'; // Import your custom CSS for styling

// // WalletInfo Component
// const WalletInfo = ({ walletData }) => {
//   return (
//     <div className="wallet-info">
//       <h2>Wallet Balance: {walletData.balance}</h2>
//       <p>{walletData.rank} | Profit per hour: {walletData.profitPerHour}</p>
//       <h2>{walletData.userName}</h2>
//       <p>Email: {walletData.email}</p>
//     </div>
//   );
// };

// // CardItem Component
// const CardItem = ({ card }) => {
//   return (
//     <div className="card">
//       <h3>{card.title}</h3>
//       <p>{card.description}</p>
//       <p>Amount: {card.amount}</p>
//       <p>Period: {card.period}</p>
//       <button className="deposit-btn">Deposit</button>
//     </div>
//   );
// };

// // CardList Component
// const CardList = ({ cardData }) => {
//   return (
//     <div className="cards-section">
//       {cardData.map((card, index) => (
//         <CardItem key={index} card={card} />
//       ))}
//     </div>
//   );
// };

// // BottomNav Component
// const BottomNav = ({ navItems }) => {
//   return (
//     <div className="bottom-nav">
//       {navItems.map((item, index) => (
//         <div key={index} className="nav-item">{item}</div>
//       ))}
//     </div>
//   );
// };

// // SettingsIcon Component
// const SettingsIcon = () => {
//   return <div className="settings-icon">⚙️</div>;
// };

// // Main DigitalTrade Component
// const DigitalTrade = () => {
//   // Dummy data objects
//   const walletData = {
//     balance: '22,749,729 USDT',
//     rank: 'Legendary (CEO)',
//     profitPerHour: '+126.42K',
//     userName: 'Pheeky Moore',
//     email: 'pheeky@gmail.com',
//   };

//   const cardData = [
//     {
//       title: 'Daily Reward',
//       description: 'Claim your daily 10% reward in 09:55',
//       amount: '$400',
//       period: '30 Days',
//     },
//     {
//       title: 'Daily Reward',
//       description: 'Claim your daily 10% reward in 09:55',
//       amount: '$400',
//       period: '30 Days',
//     },
//     {
//       title: 'Daily Reward',
//       description: 'Claim your daily 10% reward in 09:55',
//       amount: '$400',
//       period: '30 Days',
//     },
//   ];

//   const navItems = ['Home', 'Investment History', 'Wallet', 'Profile'];

//   return (
//     <div className="digital-trade">
//       {/* Top Section: Wallet Balance & Details */}
//       <div className="wallet-section">
//         {/* <WalletInfo walletData={walletData} />
//         <SettingsIcon /> */}
//               {/* Top Section: Wallet Balance & Details */}
//       <div className="wallet-section">
//         <div className="wallet-info">
//           <h2>Wallet Balance: 22,749,729 USDT</h2>
//           <p>Legendary (CEO) | Profit per hour: +126.42K</p>
//         </div>
//         <div className="wallet-options">
          
//           <div className="settings-icon">⚙️</div>
//         </div>
//         <div className="wallet-info">
//           <h2>Pheeky Moore</h2>
//           <p>Email: pheeky@gmail.com</p>
//         </div>
        
//       </div>
//       </div>

//       {/* Cards Section with Deposit Buttons */}
//       <div className="cards-section-container">
//         <CardList cardData={cardData} />

//         {/* Center Section: Mascot Image */}
//         <div className="mascot-section">
//           <img src="./images/dlogo.png" alt="Hamster Mascot" className="mascot-img" />
//         </div>

//         {/* Bottom Navigation */}
//         <BottomNav navItems={navItems} />
//       </div>
//     </div>
//   );
// };

// export default DigitalTrade;


// import React from 'react';
// import './DigitalTrade.css'; // Import your custom CSS for styling

// const DigitalTrade = () => {
//   return (
//     <div className="digital-trade">
//       {/* Top Section: Wallet Balance & Details */}
//       <div className="wallet-section">
//         <div className="wallet-info">
//           <h2>Wallet Balance: 22,749,729 USDT</h2>
//           <p>Legendary (CEO) | Profit per hour: +126.42K</p>
//         </div>
//         <div className="wallet-options">
          
//           <div className="settings-icon">⚙️</div>
//         </div>
//         <div className="wallet-info">
//           <h2>Pheeky Moore</h2>
//           <p>Email: pheeky@gmail.com</p>
//         </div>
        
//       </div>

//       {/* Cards Section with Deposit Buttons */}
//       <div className="cards-section-container">
//       <div className="cards-section">
//         <div className="card">
//           <h3>Daily Reward</h3>
//           <p>Claim your daily 10% reward in 09:55</p>
//           <p>Amount: $400 </p>
//           <p>Period: 30 Days</p>
//           <button className="deposit-btn">Deposit</button>
//         </div>
//         <div className="card">
//           <h3>Daily Reward</h3>
//           <p>Claim your daily 10% reward in 09:55</p>
//           <p>Amount: $400 </p>
//           <p>Period: 30 Days</p>
//           <button className="deposit-btn">Deposit</button>
//         </div>
//         <div className="card">
//           <h3>Daily Reward</h3>
//           <p>Claim your daily 10% reward in 09:55</p>
//           <p>Amount: $400 </p>
//           <p>Period: 30 Days</p>
//           <button className="deposit-btn">Deposit</button>
//         </div>
//       </div>

//       {/* Center Section: Mascot Image */}
//       <div className="mascot-section">
//         <img src="./images/dlogo.png" alt="Hamster Mascot" className="mascot-img" />
//       </div>

//       {/* Bottom Navigation */}
//       <div className="bottom-nav">
//         <div className="nav-item">Home</div>
//         <div className="nav-item">Investment History</div>
//         <div className="nav-item">Wallet</div>
//         <div className="nav-item">Profile</div>
//       </div>
//     </div>
//     </div>
//   );
// };

// export default DigitalTrade;
